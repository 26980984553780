import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { ChkBox, CustomSelect } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox, ItemSection, ItemSectionTitle, PageSizing } from "component/app/items";
import { LoadingBox, Popup, RightPopup } from "component/basic/popup";
import { WashListItem, WashTypeCaption } from "component/wash/item";
import { comFormat, getDateGap, setDateTime } from "js/function";
import { PaymentBox, PaymentItem, PaymentSuccess } from "component/payment/item";
import { NotItems } from "component/basic/notItems";
import { CardItem } from "component/mypage/card";

const PackageDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const washApi = useGet({
        url:`/package/product/detail?product_id=${id}`,
        loginType:"login"
    });
    const washData = washApi?.data?.product;

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="패키지 상품 정보"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    {washData?.product_packages?.map((item,i)=>(
                        <WashListItem
                            key={i}
                            addClass={item?.is_bonus === 1 ? 'bonus' : ''}
                            id={item.id}
                            name={item.name||""}
                            badges={item.product_badges||""}
                            caption={item.contents||""}
                            option={item.product_options||[]}
                            subOptionTitle={item.relation_product_name||null}
                            subOption={item.relation_product_options||[]}
                            include_inside_full_care={item.include_inside_full_care}
                            inside_full_care_option_count={item.inside_full_care_option_count}
                            use_once={item.use_once}
                            use_month={item.use_month}
                            price={item.price}
                            month_price={item.month_price}
                            btn0Text={item?.product_count + '개 지급'}
                            textBtn={true}
                            is_bonus={item?.is_bonus}
                            expiration_day={item?.expiration_day}
                        />
                    ))}
                </ItemSection>
                <ItemSection>
                    패키지 상품 안내사항 <br/><br/>
                    <div className="washListItem_packageGuideBox">
                        <p className="washListItem_packageGuideText">패키지 상품은 여러 이용권을 한 번에 할인된 금액으로 구매 가능한 상품으로 포함된 이용권을 1개 이상 사용하실 경우 부분 환불이 불가합니다.<br/></p>
                        <p className="washListItem_packageGuideText">패키지 상품에 보너스 이용권이 포함된 경우 유효기간이 존재하며 기간 내 사용하지 않을 경우 이용권은 소멸합니다.<br/></p>
                        <p className="washListItem_packageGuideText">패키지 상품에 포함된 이용권은 다른 사용자에게 선물이 불가합니다.<br/></p>
                    </div>
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={`총 ${comFormat(washData?.price)}원 구매하기`}
                        func={()=>{
                            navigate(`/package/payment/${id}`)
                        }}
                    />
                </BtnBox>
            </ContentsSection>
        </PageSizing>
    );
};

export default PackageDetail;