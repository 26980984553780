import useGet from "api/useGet";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, EditerBox, PageSizing } from "component/app/items";

const SubTerms = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const termsType = param.type;

    const termsApi = useGet({
        url:"/term",
    });
    const termsData = termsApi?.data?.terms;
    const [termsItem,setTermsItem] = useState(null);

    useEffect(() => {
        if(termsData){
            let termsFilter = termsData?.filter((el) => termsType == el.id)[0];
            if(termsFilter){
                setTermsItem(termsFilter);
            }else{
                navigate("/pageErr");
            }
        }
    }, [termsData]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={termsItem?.title}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents">
                <EditerBox addClass="termsText" data={termsItem?.contents}/>
            </ContentsSection>
        </PageSizing>
    );
};

export default SubTerms;