import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, EditerBox, ItemSection, PageSizing } from "component/app/items";
import { RightPopup } from "component/basic/popup";
import { Footer, FooterManu } from "component/elements/footer";
import { MyPageMenuItem, MyPageTitle } from "component/mypage/mypage";
import { comFormat } from "js/function";

const Mypage = (props) => {
    const navigate = useNavigate();

    const [update,setUpdate] =  useState(0);
    const [terms,setTerms] =  useState({});
    const [rightOpenType,setRightOpenType] =  useState(false);

    const mypageApi = useGet({
        url:`/mypage?update=${update}`,
        loginType:"login"
    });
    const mypageData = mypageApi?.data?.user_info;
    const alarmView = mypageData?.alarm_view||0;
    const MyCash = mypageData?.my_cash||0;
    const myCoupon = mypageData?.my_coupon||0;
    const grade = mypageApi?.data?.grade;

    const termsApi = useGet({
        url:"/term?type=join",
    });
    const termsData = termsApi?.data?.terms;

    const rightOpen = (id,title,contents) => {
        let termsItem = {
            id:id,
            title:title,
            contents:contents
        }
        setTerms(termsItem);
        setRightOpenType(true);
    }

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
                alarmBtn={true}
                alarmType={alarmView == 0 ? false : true}

                grade={grade}
                carNumber={mypageData?.number ? mypageData?.number : ""}
            />
            <ContentsSection addClass="headerContents footerContents">
                <ItemSection addClass="">
                    <MyPageTitle
                        name={mypageData?.nickname}
                    />
                </ItemSection>
                <ItemSection addClass="minLine notPaddingSide">
                    <MyPageMenuItem
                        icon="/assets/images/icon/setting_icon_7.svg"
                        name="내 정보 수정"
                        subText=""
                        func={()=>{navigate("/mypage/edit")}}
                    />
                    <MyPageMenuItem
                        icon="/assets/images/icon/setting_icon_0.svg"
                        name="나의 캐시"
                        subText={`${comFormat(MyCash)}C`}
                        func={()=>{navigate("/mypage/cash")}}
                    />
                    <MyPageMenuItem
                        icon="/assets/images/icon/setting_icon_1.svg"
                        name="결제 카드 관리"
                        subText=""
                        func={()=>{navigate("/mypage/card")}}
                    />
                    <MyPageMenuItem
                        icon="/assets/images/icon/setting_icon_2.svg"
                        name="쿠폰 관리"
                        subText={`${comFormat(myCoupon)}장`}
                        func={()=>{navigate("/mypage/coupon")}}
                    />
                    <MyPageMenuItem
                        icon="/assets/images/icon/setting_icon_6.svg"
                        name="친구 추천하기"
                        subText=""
                        func={()=>{navigate("/event/recommended")}}
                    />
                    <MyPageMenuItem
                        icon="/assets/images/icon/setting_icon_3.svg"
                        name="고객센터"
                        subText=""
                        func={()=>{navigate("/notice")}}
                    />
                    <MyPageMenuItem
                        icon="/assets/images/icon/setting_icon_4.svg"
                        name="설정"
                        subText=""
                        func={()=>{navigate("/mypage/setting")}}
                    />
                </ItemSection>
                <ItemSection addClass="minLine notPaddingSide">
                    {termsData?.map((item, i) => (
                        <MyPageMenuItem
                            key={i}
                            name={item.title}
                            func={()=>{rightOpen(item.id,item.title,item.contents)}}
                        />
                    ))}
                </ItemSection>
                <Footer/>
            </ContentsSection>
            <FooterManu menuChk="4"/>
            <RightPopup
                topTitle={terms?.title}
                addClass={rightOpenType ? "active bottomBtn" : " bottomBtn"}
                closePopup={() => {setTerms({});setRightOpenType(false)}}
                openType={rightOpenType}
                bType={true}
            >
                <EditerBox addClass="termsText" data={terms?.contents}/>
            </RightPopup>
        </PageSizing>
    );
};

export default Mypage;