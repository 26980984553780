import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import { NotItems } from "component/basic/notItems";
import { bottomChk } from "js/function";
import { MyPointInfo, PointItem, PointItemBox } from "component/mypage/point";

const Cash = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(1);

    const [itemList,setItemList] =  useState([]);

    let lastPage = false;
    let settingChk = 0;

    const [popupData,setPopupData] =  useState(null);


    const pageDataApi = useGet({
        url:`/cash?page=${page}`,
        loginType:"login"
    });
    const myCash = pageDataApi?.data?.cash;
    const pageData = pageDataApi?.data?.user_cashs;

    function addPage(){
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(pageData?.data && pageData?.data.length > 0){
            if(page == 1){
                setItemList([...pageData?.data]);
            }else{    
                setItemList(prev => [...prev, ...pageData?.data]);
            }
        }else{
            setItemList([]);
        }

        lastPage = pageData?.current_page < pageData?.last_page;

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pageDataApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="나의 캐시"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerContents">
                <MyPointInfo
                    myPoint={myCash}
                />
                <PointItemBox>
                {itemList?.length > 0 ?
                    <>
                        {itemList && itemList?.map((item, i) => (
                            <PointItem
                                key={i}
                                id={item.id}
                                addClass=""
                                type={item.type}
                                created_at={item.created_at}
                                price={item.price}
                                remain_price={item.remain_price}
                            />
                        ))}
                    </>
                :
                    <NotItems
                        addClass="fullPage"
                        img=""
                        text={`캐시 내역이 없습니다.`}
                    />
                }
                </PointItemBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Cash;