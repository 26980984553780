import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputBoxCol2, InputItemBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, InputSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import { cardDateSetting } from "js/function";

const CardAdd = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);
    const [btnChk,setBtnChk] =  useState(true);

    const [cardNo,setCardNo] =  useState("");
    const [expYM,setExpYM] =  useState("");
    const [birth,setBirth] =  useState("");
    const [cardPw,setCardPw] =  useState("");
    const [cardType,setCardType] =  useState({});

    const [submitChk,setSubmitChk] =  useState(true);

    function cardAdd(){
        if(submitChk){
            setSubmitChk(false);

            let formData = new FormData();
            formData.append("card_no", cardNo);
            formData.append("exp_year", expYM?.split("/")[1]);
            formData.append("exp_month", expYM?.split("/")[0]);
            formData.append("birth", birth);
            formData.append("card_pw", cardPw);
            formData.append("card_type", cardType?.id);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/card/register`,
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"카드 등록 완료",
                        text:"카드 등록이 완료되었습니다.",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{
                            navigate(-1);
                        },
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    useEffect(() => {
        if(cardNo.length == 16 && expYM.length == 5 && (cardType?.id === 2 ? (birth.length >= 6 && birth.length <= 10) : birth.length == 6) && cardPw.length == 2 && cardType?.id){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [cardNo,expYM,birth,cardPw,cardType]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="결제 카드 관리"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection>
                    <CustomSelect
                        inputName="카드 타입"
                        placeholder="카드 타입"
                        value={cardType?.id}
                        func={(name,val) => {
                            let dataSet = {
                                id:val,
                                name:name
                            }
                            setCardType(dataSet);
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={[
                            {id:1,name:"개인카드"},
                            {id:2,name:"법인카드"}
                        ]}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="카드번호 ‘-’ 제외 입력"
                        placeholder="카드번호 ‘-’ 제외 입력"
                        value={cardNo}
                        max={16}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setCardNo(value)}}
                    />
                    <InputBoxCol2>
                        <InputItemBox
                            inputType={null}
                            addClass=""
                            inputName="유효기간 MM / YY"
                            placeholder="유효기간 MM / YY"
                            value={expYM}
                            max={5}
                            regexp={/[^0-9\/]/gi}
                            inputMode="numeric"
                            pattern="[0-9]*"
                            func={(value)=>{setExpYM(cardDateSetting(value))}}
                        />
                        <InputItemBox
                            inputType="password"
                            addClass=""
                            inputName="비밀번호 앞 2자리"
                            placeholder="비밀번호 앞 2자리"
                            value={cardPw}
                            max={2}
                            regexp={null}
                            func={(value)=>{setCardPw(value)}}
                        />
                    </InputBoxCol2>
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName={cardType?.id === 2 ? "생년월일 6자리 또는 사업자등록번호" : "생년월일 6자리"}
                        placeholder={cardType?.id === 2 ? "생년월일 6자리 또는 사업자등록번호" : "생년월일 6자리"}
                        value={birth}
                        max={cardType?.id === 2 ? 10 : 6}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setBirth(value)}}
                        caption={cardType?.id === 2 ? "기명의 경우 생년월일 / 무기명일 경우 사업자등록번호를 입력해 주세요." : ""}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"등록 완료"}
                        disabled={false}
                        func={() => {cardAdd()}}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default CardAdd;