import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ContentsSection, ItemSection, ItemSectionTitle, PageSizing, TimeSelBox } from "component/app/items";
import { comFormat, setDateTime } from "js/function";
import { DetailItem } from "component/detailing/item";
import { DatePicer } from "component/basic/formItems";
import { NotItems } from "component/basic/notItems";

const DetailingDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const location = useLocation();
    const pageData = location?.state ? location?.state : null;
    const productData = pageData?.productData;

    const [btnChk,setBtnChk] =  useState(true);

    const [month,setMonth] =  useState("");
    const [day,setDay] =  useState("");
    const [time,setTime] =  useState("");
    const [excludeDates,setExcludeDates] =  useState([]);
    const [dateChk,setDateChk] = useState(false);

    const detailingDateApi = useGet({
        url:id && month ? `/detailing/month?month=${month}&detailing_product_id=${id}` : null,
        loginType:"login"
    });

    const detailingDateData = id && month ? detailingDateApi?.data?.detailing_reservations : "";

    const detailingTimeApi = useGet({
        url:id && month && day ? `/detailing/time?date=${month}-${day}&detailing_product_id=${id}` : null,
        loginType:"login"
    });

    const detailingTimeData = id && month && day ? detailingTimeApi?.data?.detailing_times||[] : [];

    useEffect(() => {
        let nowDate = new Date();
        setMonth(setDateTime(nowDate,"yymm","hymd"));
        // setDay(setDateTime(nowDate,"day",null));

        setDateChk(!dateChk);

        if(!productData){
            navigate(-1);
        }
    }, []);

    useEffect(() => {
        if(detailingDateData){
            let excludeDateSetting = [];
            detailingDateData?.forEach(function(item,i){
                excludeDateSetting.push(item.date);
                if(detailingDateData?.length - 1 == i){
                    setExcludeDates(excludeDateSetting);
                    setDateChk(!dateChk);
                }
            });
        }
    }, [detailingDateApi]);

    useEffect(() => {
        if(month !== "" && day !== "" && time !== "" && id){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [month,day,time,id]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="예약하기"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    <DetailItem
                        addClass=""
                        id={productData?.id}
                        name={productData?.name}
                        caption={productData?.contents}
                        requiredTime={productData?.requiredTime}
                        service={productData?.service}
                        notes={productData?.notes}
                        btnText={`${comFormat(productData?.price)}원`}
                        textType={true}
                    />
                </ItemSection>
                <ItemSection>
                    <ItemSectionTitle
                        contents="날짜"
                    />
                    <DatePicer
                        inputName=""
                        placeholder=""
                        value={month && day ? month + "-" + day : null}
                        dataChk={dateChk}
                        minCount={false}
                        inline={true}
                        excludeDates={excludeDates}
                        func={(date) => {
                            setMonth(setDateTime(date,"yymm","hymd"));
                            setDay(setDateTime(date,"day",null));
                            setTime("");
                        }}
                        prevMonth={(date) => {setMonth(date)}}
                        nextMonth={(date) => {setMonth(date)}}
                    />
                </ItemSection>
                <ItemSection>
                    <ItemSectionTitle
                        contents="시간 선택"
                    />
                    {detailingTimeData && detailingTimeData.length > 0 ? 
                        <TimeSelBox
                            addClass=""
                            data={detailingTimeData}
                            func={(val)=>{setTime(val)}}
                            value={time}
                        />
                    :
                        <NotItems
                            addClass="min"
                            img={null}
                            text="날짜를 선택해주세요."
                        />
                    }
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"서비스 예약"}
                        disabled={btnChk}
                        func={() => {navigate(`/detailing/payment/${id}`,{state: {date:`${month}-${day}`,time:time,productData:productData}})}}
                    />
                </BtnBox>
            </ContentsSection>
        </PageSizing>
    );
};

export default DetailingDetail;