import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem, TextBtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { LoadingBox, Popup, SlideUpPopup, NoticePopup, ToastPopup } from "component/basic/popup";
import { Footer, FooterManu } from "component/elements/footer";
import { MainGuide, MainMenuBox, MainMenuItem, MainPopupBottomBox, MainPopupContentsBox, MainPopupContentsTitleBox, MainPopupProductBox, MainPopupReProductBox, MainPopupSubContents, MainPopupTitleBox, MainSection, MainSubTitle, MainTitle, MainVideo, WaitingTimeBanner, NoticePopupTitleBox } from "component/main/item";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';
import { NotItems } from "component/basic/notItems";
import { cookieSetItem, getParam, getTimeGap, setDateTime } from "js/function";

const Main = (props) => {
    const navigate = useNavigate();
    const varUA = navigator.userAgent;

    const [updateApi,setUpdateApi] =  useState(0);
    const mainApi = useGet({
        url:`/main?update=${updateApi}`,
        loginType:"login"
    });

    const carData = mainApi?.data?.user_car||"";
    const userId = mainApi?.data?.user_id||"";
    const nickName = mainApi?.data?.nickname||"";
    const waitingTime = mainApi?.data?.waiting_time||"";
    const alarmView = mainApi?.data?.alarm_view||0;

    const beginTime = mainApi?.data?.begin_time||null;
    const endTime = mainApi?.data?.end_time||null;
    const businessTimeCheck = mainApi?.data?.business_time_check||null;

    const grade = mainApi?.data?.grade;
    
    const status = mainApi?.data?.status;
    const visitWaitingTime = mainApi?.data?.visit_waiting_time||0;
    const product = mainApi?.data?.product;
    const recommend_product = mainApi?.data?.recommend_product;

    const eventApi = useGet({
        url:`/event?status=now`,
        loginType:"login"
    });
    const eventData = eventApi?.data?.events||"";

    const noticeApi = useGet({
        url:`/notice?main_status=1`,
        loginType:"login"
    });
    const noticeData = noticeApi?.data?.notices||"";

    const [slideUpPopupClose,setSlideUpPopupClose] =  useState(0);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [popupData,setPopupData] =  useState(null);

    const apiChkval = useRef(null);

    const checkForAccess = () => {
        if (localStorage.getItem("token") !== null) {
            if (varUA.match("autonova/Android") !== null) {
                window.autonova.autoLogin(localStorage.getItem("token"));
            } else if (varUA.match("autonova/iOS") !== null) {
                window.webkit.messageHandlers.autoLoginHandler.postMessage(localStorage.getItem("token"));
            }
        }
    };

    useEffect(() => {
        checkForAccess();
    }, []);

    useEffect(() => {
        let evPopupDate = localStorage.getItem("evPopup");
        let basicDate = evPopupDate ? 
            String(evPopupDate).indexOf("-") > 0 || String(evPopupDate).indexOf(".") > 0 ? String(evPopupDate).replace(/-\./g,"/")
            : evPopupDate : null;
		let chkDate = basicDate ? new Date(basicDate) : null;

        if((evPopupDate ? chkDate <= new Date() : true) && eventData?.data?.length > 0){
            localStorage.removeItem("evPopup");

            setToastPopupData({
                addClass:"fullContetns",
                title:null,
                closeType:true,
                closeFuncChk:false,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{
                    let nowDate = new Date();
                    nowDate.setDate(nowDate.getDate() + 1)
                    localStorage.setItem("evPopup",`${setDateTime(nowDate,"yymmdd","hymd")} 00:00:00`);
                },
                btn0:"오늘 하루 보지않기",
                btn0Type:"gColor",
                btn1:"닫기",
                btn1Type:"gColor",
                btnFunc1:()=>{},
                textBtn:null,
                textBtnFunc:()=>{}
            });
        }
    }, [eventApi]);

    useEffect(() => {
        if (apiChkval.current !== null) {
            clearInterval(apiChkval.current);
            apiChkval.current = null;
        }

        let upDateItem = 0;
        apiChkval.current = setInterval(function() {
            setUpdateApi(upDateItem++)
            if(status == 4){
                clearInterval(apiChkval.current);
                apiChkval.current = null;
            }
        }, 30000);
        
        if(getParam("terms") == 1 || getParam("terms") == 2){
            setTimeout(() => {
                window.rightOpen(getParam("terms"),getParam("terms") == 1 ? "서비스 이용약관" : "개인정보처리방침");     
            }, 1000);
        }

        return () => {
            if (apiChkval.current !== null) {
                clearInterval(apiChkval.current);
                apiChkval.current = null;
            }
        };
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
                alarmBtn={true}
                alarmType={alarmView == 0 ? false : true}

                grade={grade}
                logo={carData?.number ? false : true}
                carNumber={carData?.number ? carData?.number : null}
            />
            <ContentsSection addClass="headerContents footerContents">
                <MainTitle
                    title={`${nickName ? `<span class="gTextColor">${nickName} </span>`: "고객"}님, <br/>오늘도 좋은 하루 보내세요 :)`}
                />
                <WaitingTimeBanner
                    businessTimeCheck={businessTimeCheck}
                    title={
                        businessTimeCheck == 1 ? 
                        `약 <div class='filpCalender'><div class='topItem'><span>${waitingTime}</span></div><div class='bottomItem'><span>${waitingTime}</span></div><div class='topItemCover'><span>${waitingTime}</span></div><div class='bottomItemCover'><span>${waitingTime}</span></div></div>분 예상`
                        : `매일 ${beginTime} 오픈`}
                    subTitle={businessTimeCheck == 1 ? "세차 이용을 위한 대기시간" : "지금은 영업시간이 아니에요"}
                    btn={businessTimeCheck == 1 ? "이용 혼잡도 확인하기" : "예상 혼잡도 확인"}
                    func={()=>{navigate("/degree")}}
                />
                <MainMenuBox>
                    <MainMenuItem
                        img="/assets/images/icon/main_link_3.png"
                        text="할인 패키지"
                        func={()=>{navigate("/package")}}
                    />
                    <MainMenuItem
                        img="/assets/images/icon/main_link_0.png"
                        text="카워시"
                        func={()=>{navigate("/wash")}}
                    />
                    <MainMenuItem
                        img="/assets/images/icon/main_link_1.png"
                        text="디테일링"
                        func={()=>{navigate("/detailing")}}
                    />
                    {/*<MainMenuItem
                        img="/assets/images/icon/main_link_2.png"
                        text="덴트리페어"
                        func={()=>{
                            // navigate("/dentrepair")
                            setPopupData({
                                addClass:null,
                                title:"COMING SOON",
                                text:"서비스 준비중 입니다.",
                                closeType:true,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"확인",
                                btn0Type:"",
                                btn1:"",
                                btn1Type:"",
                                btnFunc1:()=>{}
                            });
                        }}
                    />*/}
                </MainMenuBox>
                <MainSection>
                    <MainSubTitle
                        title="오토노바의 소식 알아보기"
                    />
                    <div className="mainBoardSlideBox">
                        {eventData?.data?.length > 0 ?
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={1}
                                pagination={true}
                                loop={true}
                                onRealIndexChange={(e) => {}}
                                modules={[Pagination]}
                            >
                                {eventData?.data && eventData?.data?.map((item, i) => (
                                    <SwiperSlide key={i}>
                                        <button type="button" className="main_eventItem backgroundImg" onClick={()=>{item.sub_link ? window.location.href = item.sub_link : navigate(`/event/detail/${item?.id}`)}} style={{"backgroundImage":`url('${item?.image_url}')`}}>
                                            {/* <h1 className="main_eventItem_title" dangerouslySetInnerHTML={{__html:item.title}}/> */}
                                        </button>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            :
                            <NotItems
                                addClass="min"
                                img="/assets/images/icon/not_event.svg"
                                text="진행중인 이벤트가 없습니다."
                            />
                        }
                    </div>
                </MainSection>
                <MainVideo src="/assets/images/img/main_video.mp4"/>
                <MainSection>
                    <MainSubTitle
                        title="오토노바<br/>서비스 이용 가이드"
                    />
                    <MainGuide
                        data={[
                            {
                                title:"오토노바 이용권 구매",
                                text:"앱 또는 현장에 방문해 이용권을 구매하세요!"
                            },
                            {
                                title:"오토노바 카워시 입장",
                                text:"오토노바에 입장하면 고객님의 차량을 자동 인식!<br/>기다림 없이 빠르게, 최첨단 기계 세차를 이용해 보세요."
                            },
                            {
                                title:"실내 세차 서비스",
                                text:"7명의 디테일링 크루원들이 고객님 차량에만 집중해요. 평균 14분 내 이루어지는 고퀄리티 실내 세차를 이용하세요."
                            }
                        ]} 
                    />
                </MainSection>
                <MainSection>
                    <MainSubTitle
                        title="오토노바만의 특별한 구독권,<br/>알고 계셨나요?"
                    />
                    <div className="mainTicketBanner">
                        <div className="mainTicketBanner_info">
                            <img src="/assets/images/icon/autonova.png"/>
                            <p className="mainTicketBanner_text">한 달 동안 매일 자유롭게 이용 가능한<br/>오토노바의 고퀄리티 카워시 구독권을 이용해 보세요.</p>
                        </div>
                        <BtnItem
                            addClass="subType line"
                            contents="구독권 알아보기"
                            disabled={false}
                            func={() => {navigate("/wash")}}
                        />
                    </div>
                </MainSection>
                <Footer addClass="bottomPaddingItem" termsType={true}/>
            </ContentsSection>
            <Popup
                data={popupData}
            />

            {localStorage.getItem("token") && (status || status == 0) ? 
            <SlideUpPopup
                addClass=""
                title={<MainPopupTitleBox caption={`${visitWaitingTime}분`}/>}
                slideUpPopupClose={slideUpPopupClose}
            >
                <MainPopupContentsBox>
                    {(status == 0 && product?.reservation_id) ?
                        <>
                            <MainPopupContentsTitleBox
                                title={`선택하신 이용권을 확인했어요!<br/>잠시만 기다려주시면 세차가 시작될거에요.`}
                            />
                            <MainPopupBottomBox>
                                <MainPopupTitleBox addClass="bColor" caption={`${visitWaitingTime}분`}/>
                                <MainPopupProductBox info={`서비스 ${product?.type||""}`} name={product?.name||""}/>
                            </MainPopupBottomBox>
                        </>
                    : status == 0 ?
                    <>
                        <MainPopupSubContents
                            icon="welcome_icon"
                            title={`WELCOME !`}
                            text={`오토노바에 오신 것을 환영합니다 :)<br/>이용하실 서비스를 선택하길 기다리고 있어요.`}
                        />
                        <MainPopupBottomBox>
                            <MainPopupTitleBox addClass="bColor" caption={`${visitWaitingTime}분`}/>
                        </MainPopupBottomBox>
                    </>
                    : status == 1 || (status == 2 && product && product?.include_inside == 1 && product?.detailing_status == 0 && product?.queue_date && (getTimeGap(product?.queue_date,null,"hh") <= 1)) ?
                    <>
                        <MainPopupContentsTitleBox
                            title={product?.include_inside == 1 ? `7명의 디테일링 크루원이 실내 세차를 진행하고 있어요.<br/>잠시만 기다려주세요 :)`
                                    : `고객님의 차량을 깨끗하게 세차하고 있어요.<br/>잠시만 기다려주세요.`}
                        />
                        <MainPopupBottomBox>
                            <MainPopupTitleBox addClass="bColor" caption={`${visitWaitingTime}분`}/>
                            <MainPopupProductBox info={`서비스 ${product?.type||""}`} name={product?.name||""}/>
                        </MainPopupBottomBox>
                    </>
                    : status == 2 ?
                    <>
                        <MainPopupSubContents
                            icon="wach_com_icon"
                            title={`세차 완료!`}
                            text={`고객님의 차량 세차를 완료했어요${recommend_product ? `,<br/>오늘 서비스가 만족스러우셨다면 무제한으로<br/>이용 가능한 구독권을 구매해보시는건 어떠세요?${recommend_product?.include_inside == 1 ? "<br/><span class='gTextColor'>*구독권에는 실내세차가 포함되지 않습니다.</span>" : ""}` : ""}`}
                        />
                        {recommend_product ? 
                        <MainPopupBottomBox>
                            <MainPopupReProductBox
                                info={`서비스 ${recommend_product?.type}`}
                                name={recommend_product?.name}
                                price={recommend_product?.price}
                                id={recommend_product?.id}
                            />
                            <div className="mainPopup_textBtn">
                                <TextBtnItem addClass="underLine" contents="괜찮아요, 다음에 할게요" func={() => {window.location.reload(true)}}/>
                            </div>
                        </MainPopupBottomBox>
                        :""}
                    </>
                    :""}
                </MainPopupContentsBox>
            </SlideUpPopup>
            : noticeData ? <NoticePopup
                    addClass=""
                    title={<NoticePopupTitleBox datas={noticeData}/>}
                    slideUpPopupClose={slideUpPopupClose}
                ></NoticePopup> : ""}
            <ToastPopup
                data={toastPopupData}
            >
                <div className="mainPopupBoardSlideBox">
                    {eventData?.data?.length > 0 ?
                        <Swiper
                            spaceBetween={10}
                            slidesPerView={1}
                            pagination={{
                                type: "fraction",
                            }}
                            loop={true}
                            onRealIndexChange={(e) => {}}
                            modules={[Pagination, Navigation]}
                        >
                            {eventData?.data && eventData?.data?.map((item, i) => (
                                <SwiperSlide key={i}>
                                    <button type="button" className="mainPopup_eventItem backgroundImg" onClick={()=>{item.sub_link ? window.location.href = item.sub_link : navigate(`/event/detail/${item?.id}`)}} style={{"backgroundImage":`url('${item?.image_url}')`}}>
                                        {/* <h1 className="mainPopup_eventItem_title" dangerouslySetInnerHTML={{__html:item.title}}/> */}
                                    </button>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        :
                        ""
                    }
                </div>
            </ToastPopup>
            <FooterManu menuChk="0"/>
        </PageSizing>
    );
};

export default Main;