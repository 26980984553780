import Private from "pages/private/Private";
import PublicOnly from "pages/private/publicOnly";
import React, { useEffect, useState } from "react";
import * as fatchSet from "../api/api";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "component/basic/scrollTopSet";
import { Popup, RightPopup } from "component/basic/popup";
import useGet from "api/useGet";
import WelCome from "pages/member/WelCome";
import { ErrorBoundary } from "react-error-boundary";
import Login from "pages/member/Login";
import Terms from "pages/member/Terms";
import Join from "pages/member/Join";
import JoinCom from "pages/member/JoinCom";
import MemberSurvey from "pages/member/Survey";
import PackageList from "pages/package/List";
import WashList from "pages/wash/List";
import Card from "pages/mypage/Card";
import CardAdd from "pages/mypage/CardAdd";
import FindId from "pages/member/FindId";
import FindPw from "pages/member/FindPw";
import Main from "pages/main/Main";
import Degree from "pages/main/Degree";
import Cafe from "pages/main/Cafe";
import DetailingList from "pages/detailing/List";
import DetailingPayment from "pages/detailing/Payment";
import PackageDetail from "pages/package/Detail";
import PackagePayment from "pages/package/Payment";
import WashPayment from "pages/wash/Payment";
import PaymentSuccessPage from "pages/private/Payment";
import DentrepairList from "pages/dentrepair/List";
import DentrepairCs from "pages/dentrepair/Cs";
import DentrepairAdd from "pages/dentrepair/Reservation";
import WashTicket from "pages/wash/Ticket";
import WashTicketDetail from "pages/wash/TiketDetail";
import WashTicketGift from "pages/wash/Gift";
import WashTicketCancel from "pages/wash/Cancel";
import DetailingTicket from "pages/detailing/Tiket";
import DetailingTicketDetail from "pages/detailing/TiketDetail";
import DetailingTicketCancel from "pages/detailing/Cancel";
import DentrepairTicket from "pages/dentrepair/Tiket";
import DentrepairTicketDetail from "pages/dentrepair/TiketDetail";
import DentrepairTicketCancel from "pages/dentrepair/Cancel";
import ReportAbout from "pages/report/About";
import Setting from "pages/mypage/Setting";
import Notice from "pages/board/Notice";
import BoardDetail from "pages/board/BoardDetail";
import Event from "pages/board/Event";
import Faq from "pages/board/Faq";
import Coupon from "pages/mypage/Coupon";
import Cash from "pages/mypage/Cash";
import CashRefund from "pages/mypage/CashRefund";
import CashAdd from "pages/mypage/CashAdd";
import Alarm from "pages/mypage/Alarm";
import Mypage from "pages/mypage/Mypage";
import Edit from "pages/mypage/Edit";
import PhoneEdit from "pages/mypage/PhoneEdit";
import CarEdit from "pages/mypage/CarEidt";
import ReportList from "pages/report/List";
import ReportDetail from "pages/report/Detail";
import { EditerBox } from "component/app/items";
import { ChannelTalkHide } from "component/app/channelTalk";
import SubTerms from "pages/main/Terms";
import Appstore from "pages/Appstore";
import Recommended from "pages/board/Recommended";
import DetailingDetail from "pages/detailing/Detail";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);
    const [termsId,setTermsId] = useState("");
    const [popupData,setPopupData] =  useState(null);

    window.logIn = function(){
        setToken(true);
    }
    window.logOut = function(){
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        setToken(false);
    }

    const [terms,setTerms] =  useState("");
    const [rightOpenType,setRightOpenType] =  useState(false);

    const termsApi = useGet({
        url:`/term`
    });
    const termsData = termsApi?.data?.terms;

    window.rightOpen = (id,title) => {
        setTermsId(id)

        if(termsData){
            setTerms({
                title: title,
                contents: termsData?.filter((el) => el?.id == id)[0] ? termsData?.filter((el) => el?.id == id)[0]?.contents : ""
            })
            setRightOpenType(true)
        }
    }

    window.joinChkApi = () => {
        if(localStorage.getItem("token")){
            fetch(`${process.env.REACT_APP_API_URL}/join/check`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Accept' : 'application/json'
                }
            }).then(function(response) {
                return response.json();
            }).then(function(data) {
                if(data?.code === "3002" || data?.code === "3001"){
                    setPopupData({
                        addClass:null,
                        title:"로그인 실패",
                        text:"회원가입이 정상적으로 완료되지 않아<br/>로그인이 불가합니다.<br/><b>회원가입을 다시 시도해주세요.</b>",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{
                            window.location.replace("/welCome");
                            window.logOut();
                            setTimeout(() => {
                                setPopupData(null);
                            }, 200);
                        },
                        btn0:"회원가입",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            });
        }
    }

    window.sns_login = async (type, token) => {
        const formData = new FormData();
        formData.append("code", token);
        formData.append("is_token", 1);

        fetch(`https://api.autonova.co.kr/api/${type}/login`, {
            method: "POST", body: formData
        }).then(function (response) {
                return response.json();
        }).then((data) => {
            if(data.success === true){
                if (data.data.type == "login") {
                    localStorage.setItem("token", data.data.token.access_token);
                    localStorage.setItem("refresh_token", data.data.token.refresh_token);
                    localStorage.setItem("carSize", data.data.carSize);

                    window.logIn();

                    setTimeout(() => {
                        window.joinChkApi();
                        
                        if (sessionStorage.getItem("setUrl")) {
                            let reUrl = sessionStorage.getItem("setUrl");
                            sessionStorage.removeItem("setUrl");
                            window.location.href = reUrl;
                        } else {
                            window.location.href = '/';
                        }
                    }, 100);
                } else {
                    sessionStorage.setItem("token", data.data.token.access_token);
                    sessionStorage.setItem("refresh_token", data.data.token.refresh_token);

                    window.location.href = '/member/terms/sns';
                }
            } else {
                window.alert(data.alert);
                window.location.href = "/";
            }
        }).catch((e) => {
            window.location.href = "/";
        });
    };

    useEffect(()=>{
        window.joinChkApi();
    },[]);

    //로그인,비로그인 무관 페이지
    const publicRoutes = [
        {
            exact: true,
            path: "/pageErr",
            component: <PublicOnly/>,
        },
        {
            exact: true,
            path: "/",
            component: <Navigate to="/main" />,
        },
        {
            exact: true,
            path: "/main",
            component: <Main/>,
        },
        // {
        //     exact: true,
        //     path: "/member/joinSuccess",
        //     component: <JoinCom/>,
        // },
        {
            exact: true,
            path: "/degree",
            component: <Degree/>,
        },
        {
            exact: true,
            path: "/package",
            component: <PackageList/>,
        },
        {
            exact: true,
            path: "/wash",
            component: <WashList/>,
        },
        {
            exact: true,
            path: "/detailing",
            component: <DetailingList/>,
        },
        // {
        //     exact: true,
        //     path: "/dentrepair",
        //     component: <DentrepairList/>,
        // },
        {
            exact: true,
            path: "/payment/result",
            component: <PaymentSuccessPage/>,
        },
        {
            exact: true,
            path: "/subTerms/:type",
            component: <SubTerms/>,
        },
        {
            exact: true,
            path: "/appstore",
            component: <Appstore/>,
        },
    ];
    //비로그인 한정 페이지
    const publicRoutesOnly = [
        {
            exact: true,
            path: "/welCome",
            component: <WelCome/>,
        },
        {
            exact: true,
            path: "/member/login",
            component: <Login/>,
        },
        {
            exact: true,
            path: "/member/terms/:type",
            component: <Terms/>,
        },
        {
            exact: true,
            path: "/member/join/:type",
            component: <Join/>,
        },
        {
            exact: true,
            path: "/member/find/id",
            component: <FindId/>,
        },
        {
            exact: true,
            path: "/member/find/pw",
            component: <FindPw/>,
        },
    ];
    //로그인 한정 페이지
    const privateRoutes = [
        // {
        //     exact: true,
        //     path: "/member/survey",
        //     component: <MemberSurvey/>,
        // },
        {
            exact: true,
            path: "/package/detail/:id",
            component: <PackageDetail/>,
        },
        {
            exact: true,
            path: "/package/payment/:id",
            component: <PackagePayment/>,
        },
        {
            exact: true,
            path: "/wash/payment/:id/:type",
            component: <WashPayment/>,
        },
        {
            exact: true,
            path: "/mypage/card",
            component: <Card/>,
        },
        {
            exact: true,
            path: "/mypage/card/add",
            component: <CardAdd/>,
        },
        // {
        //     exact: true,
        //     path: "/cafe",
        //     component: <Cafe/>,
        // },
        {
            exact: true,
            path: "/detailing/reservation/app/:id",
            component: <DetailingDetail/>,
        },
        {
            exact: true,
            path: "/detailing/payment/:id",
            component: <DetailingPayment/>,
        },
        // {
        //     exact: true,
        //     path: "/dentrepair/cs/:id",
        //     component: <DentrepairCs/>,
        // },
        // {
        //     exact: true,
        //     path: "/dentrepair/add/:id",
        //     component: <DentrepairAdd/>,
        // },
        {
            exact: true,
            path: "/wash/ticket",
            component: <WashTicket/>,
        },
        {
            exact: true,
            path: "/wash/ticket/detail/:id",
            component: <WashTicketDetail/>,
        },
        {
            exact: true,
            path: "/wash/gift/:id",
            component: <WashTicketGift/>,
        },
        {
            exact: true,
            path: "/wash/cancel/:id",
            component: <WashTicketCancel/>,
        },
        {
            exact: true,
            path: "/detailing/ticket",
            component: <DetailingTicket/>,
        },
        {
            exact: true,
            path: "/detailing/ticket/detail/:id",
            component: <DetailingTicketDetail/>,
        },
        {
            exact: true,
            path: "/detailing/cancel/:id",
            component: <DetailingTicketCancel/>,
        },
        // {
        //     exact: true,
        //     path: "/dentrepair/ticket",
        //     component: <DentrepairTicket/>,
        // },
        // {
        //     exact: true,
        //     path: "/dentrepair/ticket/detail/:id",
        //     component: <DentrepairTicketDetail/>,
        // },
        // {
        //     exact: true,
        //     path: "/dentrepair/cancel/:id",
        //     component: <DentrepairTicketCancel/>,
        // },
      
        {
            exact: true,
            path: "/mypage/setting",
            component: <Setting/>,
        },
        {
            exact: true,
            path: "/mypage/coupon",
            component: <Coupon/>,
        },
        {
            exact: true,
            path: "/mypage/cash",
            component: <Cash/>,
        },
        {
            exact: true,
            path: "/mypage/cash/refund",
            component: <CashRefund/>,
        },
        {
            exact: true,
            path: "/mypage/cash/add",
            component: <CashAdd/>,
        },
        {
            exact: true,
            path: "/alarm",
            component: <Alarm/>,
        },
        {
            exact: true,
            path: "/mypage",
            component: <Mypage/>,
        },
        {
            exact: true,
            path: "/mypage/edit",
            component: <Edit/>,
        },
        {
            exact: true,
            path: "/member/edit/phone",
            component: <PhoneEdit/>,
        },
        {
            exact: true,
            path: "/member/edit/car",
            component: <CarEdit/>,
        },
        {
            exact: true,
            path: "/report/list",
            component: <ReportList/>,
        },
        // {
        //     exact: true,
        //     path: "/report/detail/:id",
        //     component: <ReportDetail/>,
        // },
        {
            exact: true,
            path: "/report/about",
            component: <ReportAbout/>,
        },
        {
            exact: true,
            path: "/notice",
            component: <Notice/>,
        },
        {
            exact: true,
            path: "/event",
            component: <Event/>,
        },
        {
            exact: true,
            path: "/:type/detail/:id",
            component: <BoardDetail/>,
        },
        {
            exact: true,
            path: "/faq",
            component: <Faq/>,
        },
        {
            exact: true,
            path: "/event/recommended",
            component: <Recommended/>,
        },
    ];

    //백화 시 노출
    const ErrorFallback = (err) => {
        console.log(err)
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <h1 className="pageErrTitle">서비스 이용에 불편을 드려 죄송합니다.</h1>
                    <p className="pageErrText">요청하신 페이지가 없거나, 오류가 발생했습니다.<br/>잠시 후 다시 확인해주세요</p>
                </div>
                <div className="pageBtn_box">
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/"}>홈으로</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ScrollToTop/>
                <ChannelTalkHide/>
                <Routes>
                    {/* 기본접근 */}
                    {publicRoutes.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={component}
                            />
                        )
                    )}

                    {/* 비로그인만 접근 */}
                    {publicRoutesOnly.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={!token ? component : <Navigate to="/main" />}
                            />
                        )
                    )}

                    {/* 로그인만 접근 */}
                    {privateRoutes.map(
                        ({ exact, path, component }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={token ? component : <Private/>}
                            />
                        )
                    )}

                    {/* 로그인 체크 */}
                    {/* <Route
                        key={`public-route-/member/login/:type`}
                        exact={true}
                        path="/member/login/:type"
                        element={!token ? <Login logIn={logIn}/> : <PublicOnly/>}
                    /> */}

                    {/* 로그아웃 체크 */}
                    {/* <Route
                        key={`public-route-/mypage/setting`}
                        exact={true}
                        path="/mypage/setting"
                        element={token ? <Setting rightOpenFunc={(id,title)=>rightOpen(id,title)} logOut={logOut}/> : <Private/>}
                    /> */}

                    {/*팝업*/}
                    {/* <Route
                        key={`public-route-/`}
                        exact={true}
                        path="/"
                        element={<Main rightOpenFunc={(id,title)=>rightOpen(id,title)} language={languageType} languageCh={(type)=>{setLanguageType(type)}}/>}
                    /> */}

                    {/*404*/}
                    <Route
                        key={`public-route-/*`}
                        exact={true}
                        path="/*"
                        element={<PublicOnly/>}
                    />
                </Routes>
                <RightPopup
                    topTitle={terms?.title}
                    addClass={rightOpenType ? "active" : " "}
                    closePopup={() => {setTerms({});setRightOpenType(false)}}
                    openType={rightOpenType}
                    bType={true}
                >
                    <EditerBox addClass="termsText" data={terms?.contents}/>
                </RightPopup>
                <Popup
                    data={popupData}
                />
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;