import { CaptionItem } from "component/app/items";

function DegreeTotalInfoBox(data){

    return (
        <div className="degreeTotalInfoBox">
            {data.children}
        </div>
    );
}

function DegreeTotalInfoItem(data){

    return (
        <button type="button" onClick={()=>{data.func()}} className={`degreeTotalInfoItem ${data.addClass||""}`}>
            <div className="degreeTotalInfoItem_nameBox">
                <img src="/assets/images/icon/car_icon.svg"/>
                <p className="degreeTotalInfoItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            </div>
            <CaptionItem
                contents={data.caption}
            />
        </button>
    );
}

function WaitingTimeBox(data){

    return (
        <div className="waitingTimeBox">
            {data.children}
        </div>
    );
}

function WaitingTimeItem(data){

    return (
        <div className={`waitingTimeItem ${data.addClass||""}`}>
            <p className="waitingTimeItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <div className="waitingTimeItem_graphBox">
                <p className="waitingTimeItem_graph" style={{width:`${data.graph}%`}}/>
            </div>
            <p className="waitingTimeItem_time" dangerouslySetInnerHTML={{__html:data.time}}/>
        </div>
    );
}

export {DegreeTotalInfoBox,DegreeTotalInfoItem,WaitingTimeBox,WaitingTimeItem};