import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { BottomErrMsg, LoadingBox, Popup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { Header, } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { ContentsSection, InputSection, PageSizing, PageTitle } from "component/app/items";
import useGet from "api/useGet";

const FindId = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(0);
    const [popupData,setPopupData] =  useState(null);
    const [toastPopupData,setToastPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    
    const [btnChk,setBtnChk] =  useState(true);

    const [cetified,setCetified] =  useState("");
    const [certifyErr,setCertifyErr] =  useState(null);
    const [timerCount,setTimerCount] =  useState("3:00");
    const [cetifiedChk,setCetifiedChk] =  useState(false);
    const [cetifiedComChk,setCetifiedComChk] =  useState(false);

    const [phone,setPhone] =  useState("");

    const [findId,setFindId] =  useState("");
    const [isProvider,setIsProvider] =  useState("");

    const copyRef = useRef(null);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const [submitChk,setSubmitChk] =  useState(true);
    
    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        setCertifyErr("");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;
                    setCertifyErr("인증시간이 초과되었습니다.");
                    setCetifiedComChk(true);
                    setCetified("");

                    setBtnChk(false);
                }
            }, 1000);
        }
    }

    function findInfo(){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("phone", phone);
            formData.append("code", cetified);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/find/email`,
                success: (data) => {
                    setLoadingBoxOpen(false);
                    setSubmitChk(true);

                    setFindId(data?.data?.email);
                    setIsProvider(data?.data?.is_provider);

                    setToastPopupData({
                        addClass:null,
                        title:null,
                        closeType:false,
                        closeFuncChk:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{},
                        textBtn:null,
                        textBtnFunc:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function cetifiedStert(type){
        if(submitChk){
            setSubmitChk(false);
            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("phone", phone);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/sms/certification`,
                success: (data) => {  
                    setSubmitChk(true);

                    timerStart();
                    setCetified("");
                    setCetifiedChk(true);
                    setLoadingBoxOpen(false);

                    setBtnChk(true);
                    if(type !== "re"){
                        setPage(page+1);
                    }
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                    setLoadingBoxOpen(false);
                }
            })
        }
    }

    function findNext(){
        if(page == 0){
            cetifiedStert();
        }else{
            setBtnChk(true);

            setLoadingBoxOpen(true);

            let formData = new FormData();
            formData.append("phone", phone);
            formData.append("code", cetified);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: `/sms/certification/check`,
                success: (data) => {
                    findInfo();

                    setCetifiedComChk(true);

                    setTimerCount("3:00");
                    setCertifyErr("");

                    clearInterval(sval.current);
                    sval.current = null;

                    setLoadingBoxOpen(false);

                    setBtnChk(true);
                },
                err: (data) => {
                    if(data.alert){
                        setCertifyErr(data.alert);
                    }
                    setLoadingBoxOpen(false);
                    setBtnChk(false);
                }
            })
        }
    }

    function copyText(){
        copyRef.current.select();
        document.execCommand('copy');
        copyRef.current.blur();
        setBottomMsgData({
            text : "클립보드에 복사되었습니다.",
            chk : bottomMsgData.chk + 1
        });
    }

    useEffect(() => {
        let phoneChk = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

        if(page == 0){
            if(phoneChk.test(phone)){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }

        if(page == 1){
            if(phoneChk.test(phone)
            && cetified.length == 6){
                setBtnChk(false)
            }else{
                setBtnChk(true)
            }
        }
    }, [phone,cetified]);

    return (
        <PageSizing addClass="headerPage">
            <Header 
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={""}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents={"아이디를 잊으셨나요?"}
                    subTitle={page == 0 ? "가입 시 입력한 휴대폰 번호를 알려주세요."
                    : page == 1 ? "인증번호를 입력해 주세요." : ""}
                />
                <InputSection>
                    {
                        page == 1 && cetifiedChk ? 
                            <InputItemBox
                                inputType={null}
                                addClass="cetifiedInput"
                                inputName="인증 번호"
                                placeholder="인증 번호"
                                value={cetified}
                                max={6}
                                readOnly={cetifiedComChk}
                                cetified={timerCount}
                                regexp={"number"}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                func={(value)=>{setCetified(value)}}
                                errMsg={certifyErr}
                                subBtn="인증번호 재발송"
                                inputSubFunc={()=>{cetifiedStert("re");setCetifiedComChk(false);setCetified("")}}
                            />
                        :
                        ""
                    }
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="휴대폰 번호"
                        placeholder="‘-’ 없이 입력"
                        value={phone}
                        max={11}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setPhone(value);setCetifiedChk(false);setCetifiedComChk(false);setCetified("");setPage(0)}}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={page == 1 ? "아이디 찾기" : "다음으로"}
                        disabled={btnChk}
                        func={() => {findNext()}}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
            <ToastPopup
                data={toastPopupData}
            >
                <ToastBasicContents
                    title="아이디 찾기 결과"
                    text="입력하신 정보로 조회되는 아이디에요."
                />
                <div className="idFindBox">
                    <h1 className="idFind_id">{findId}</h1>
                    {isProvider === 0 && (
                        <button type="button" className="copyText" onClick={() => {
                            copyText()
                        }}>아이디 복사<img src="/assets/images/basic/copy.svg"/></button>
                    )}
                    <input type="text" autoComplete="off" ref={copyRef} value={findId} className="copyInput"
                           onChange={() => {
                           }}/>
                </div>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"로그인"}
                        disabled={false}
                        func={() => {navigate("/member/login")}}
                    />
                </BtnBox>
            </ToastPopup>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default FindId;