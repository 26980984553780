import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnItem } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import { CouponItem, CouponItemBox } from "component/mypage/coupon";
import { NotItems } from "component/basic/notItems";
import { bottomChk } from "js/function";

const Coupon = (props) => {
    const navigate = useNavigate();

    const [update,setUpdate] =  useState(0);
    const [page,setPage] =  useState(1);

    const [itemList,setItemList] =  useState([]);

    let lastPage = false;
    let settingChk = 0;

    const [popupData,setPopupData] =  useState(null);
    const [couponNumber,setCouponNumber] =  useState("");

    const [btnChk,setBtnChk] =  useState(null);
    const [submitChk,setSubmitChk] =  useState(true);

    const pageDataApi = useGet({
        url:`/coupon?page=${page}&update=${update}`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data?.coupons;

    function addCoupon(){
        if(submitChk){
            setSubmitChk(false);
            let formData = new FormData();
            formData.append("coupon_id", couponNumber);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/coupon/register`,
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"쿠폰 등록 완료",
                        text:"쿠폰이 정상 등록되었습니다.",
                        closeType:true,
                        closeFunc:(e) => {
                            setItemList([]);
                            setUpdate(update + 1);
                            setCouponNumber("");
                        },
                        btnFunc0:(e)=>{
                            setItemList([]);
                            setUpdate(update + 1);
                            setCouponNumber("");
                        },
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    function addPage(){
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(pageData?.data && pageData?.data.length > 0){
            if(page == 1){
                setItemList([...pageData?.data]);
            }else{    
                setItemList(prev => [...prev, ...pageData?.data]);
            }
        }else{
            setItemList([]);
        }
        
        lastPage = pageData?.current_page < pageData?.last_page;

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pageDataApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
       if(couponNumber !== ""){
            setBtnChk(false);
       }else{
            setBtnChk(true);
       }
    }, [couponNumber]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="쿠폰"
                centerTitle={true}
                homeBtn={true}
            />
            <ContentsSection addClass="headerContents">
                <div className="couponAddBox">
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="쿠폰 번호를 입력하세요"
                        placeholder="쿠폰 번호를 입력하세요"
                        value={couponNumber}
                        max={null}
                        regexp={null}
                        func={(value)=>{setCouponNumber(value)}}
                    />
                    <BtnItem
                        addClass="subType"
                        contents="등록"
                        disabled={btnChk}
                        func={() => {addCoupon()}}
                    />
                </div>
                <CouponItemBox>
                    {itemList?.length > 0 ?
                        <>
                            {itemList && itemList?.map((item, i) => (
                                <CouponItem
                                    key={i}
                                    id={item.id}
                                    addClass=""
                                    type={item.type}
                                    value={item.value}
                                    name={item.name}
                                    status={item.is_use}
                                    start_date={item.start_date}
                                    end_date={item.end_date}
                                />
                            ))}
                        </>
                    :
                        <NotItems
                            addClass="fullPage"
                            img=""
                            text={`등록된 쿠폰이 없습니다.`}
                        />
                    }
                </CouponItemBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default Coupon;