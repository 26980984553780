import { useNavigate } from "react-router-dom";

function MyPageTitle(data){
    let navigate = useNavigate();
    return (
        <div className="myPageTitleBox">
            {data.name||"고객"} 님<br/>
            오늘도 좋은 하루 보내세요 :)
        </div>
    );
}

function MyPageMenuItem(data){

    return (
        <button type="button" className="myPageMenuItem" onClick={()=>{data.func()}}>
            <h1 className="myPageMenuItem_name">
                {data.icon ? <img src={data.icon}/> : ""}
                {data.name}
            </h1>
            <div className="myPageMenuItem_subInfo">
                {data.subText ? <p className="myPageMenuItem_subText" dangerouslySetInnerHTML={{__html:data.subText}}/> : ""}
                <img src="/assets/images/basic/setting_link_icon.svg"/>
            </div>
        </button>
    );
}

export {MyPageTitle,MyPageMenuItem};