import { LinkItem } from "component/app/items";
import { comFormat, setDateTime } from "js/function";
import { useNavigate } from "react-router-dom";

function MyPointInfo(data){
    let navigate = useNavigate();

    return (
        <div className={`myPointInfo ${data.addClass||""}`}>
            <div className="myPointBox">
                <p className="myPoint">{data.myPoint ? comFormat(data.myPoint) : 0}<img src="/assets/images/icon/cash_icon.svg"/></p>
            </div>
            <LinkItem
                data={[
                    {
                        icon:"/assets/images/icon/return_cash_icon.svg",
                        name:"환불 신청",
                        func:()=>{navigate("/mypage/cash/refund")}
                    },
                    {
                        icon:"/assets/images/icon/add_cash_icon.svg",
                        name:"캐시 충전",
                        func:()=>{navigate("/mypage/cash/add")}
                    }
                ]}
            />
        </div>
    );
}

function PointItemBox(data){

    return (
        <div className={`pointItemBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function PointItem(data){

    return (
        <div className={`pointItem ${data.addClass||""}`}>
            <div className="pointItem_infoBox">
                <p className="pointItem_type">{data.type == 1 ? "지급" : data.type == 2 ? "차감" : data.type == 3 ? "환불" : data.type == 4 ? "관리자 지급" : data.type == 5 ? "관리자 차감" : "-"}</p>
                {data.created_at && data.created_at !== "0000-00-00 00:00:00" ? <p className="pointItem_date">{setDateTime(data.created_at,"yymmddhhmmss","hymd")}</p> : "-"}
            </div>
            <div className="pointItem_pointBox">
                <p className={`pointItem_point ${data.type == 1 ? "gTextColor boldText" : ""}`}>{`${(data.type == 1 || data.type == 4) ? "" : "-"}${comFormat(data.price)}C`}</p>
                <p className="pointItem_total">{`${comFormat(data.remain_price)}C`}</p>
            </div>
        </div>
    );
}

function PointAddBtnBox(data){
    const pointData = ["10000","20000","30000","50000"]

    return (
        <div className={`pointAddBtnBox ${data.addClass||""}`}>
            {pointData.map((item,i)=>(
                <button type="button" key={i} className="pointAddBtn" onClick={()=>{data.func(item)}}>{`+${comFormat(item)}C`}</button>
            ))}
        </div>
    );
}

export {MyPointInfo,PointItemBox,PointItem,PointAddBtnBox};