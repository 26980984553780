import { NotItems } from "component/basic/notItems";
import { useNavigate } from "react-router-dom";

function CardItemBox(data){

    return (
        <div className="cardItemBox">
            {data.children}
        </div>
    );
}

function CardItem(data){
    let navigate = useNavigate();

    function cardNameImg(code){
        let cardName = null;

        if(code == "01"){
            cardName = "bc";
        }
        if(code == "03" || code == "16"){
            cardName = "hana";
        }
        if(code == "07"){
            cardName = "hyundai";
        }
        if(code == "02"){
            cardName = "kb";
        }
        if(code == "08"){
            cardName = "lotte";
        }
        if(code == "12"){
            cardName = "nh";
        }
        if(code == "04"){
            cardName = "samsung";
        }
        if(code == "06"){
            cardName = "shinhan";
        }

        return cardName;
    }

    return (
        <div className={`cardItem ${data.notCard ? "notCard" : ""} ${data.addClass||""} ${cardNameImg(data.code)||""}`}>
            {data.notCard ? 
                <NotItems
                    addClass=""
                    img="/assets/images/icon/not_card.svg"
                    text="등록된 카드가 없습니다.<br/>카드를 등록해주세요."
                />
            :
            <>
                <div className="cardItem_topInfo">
                    <div className="cardItem_head">
                        <h1 className="cardName">{cardNameImg(data.code) ? <img src={`/assets/images/card/${cardNameImg(data.code)}.svg`} className="cardNameImg"/> : data.name}</h1>
                        {data.cardDelFunc ? <button type="button" onClick={()=>{data.cardDelFunc()}} className="cardDel">
                            <img src="/assets/images/basic/cardDel.svg"/>
                        </button> : ""}
                    </div>
                    <img src="/assets/images/card/sim.svg" className="cardSimImg"/>
                </div>
                <h1 className="cardNumber">{data.cardNumber} - ****</h1>
            </>
            }
        </div>
    );
}

export {CardItemBox,CardItem};