import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, ItemSection, ItemSectionTitle, PageSizing, PageTitle } from "component/app/items";
import { DegreeTotalInfoBox, DegreeTotalInfoItem, WaitingTimeBox, WaitingTimeItem } from "component/main/degree";

const Degree = (props) => {
    const navigate = useNavigate();

    const waitingApi = useGet({
        url:`/waiting/time`,
        loginType:"login"
    });
    const waitingData = waitingApi?.data;

    const [tap,setTap] =  useState("out");
    const [tmapUrl,setTmapUrl] =  useState("/");

    //주소로 좌표 추출
    function tmapData(addr){
        if(addr){
            let geocoder = new window.kakao.maps.services.Geocoder();
            geocoder.addressSearch(addr, function(result, status) {
                if (status === window.kakao.maps.services.Status.OK) {
                    let positionData = {
                        lat:result[0].y,
                        lng:result[0].x
                    }

                    setTmapUrl(`https://apis.openapi.sk.com/tmap/app/routes?appKey=${process.env.REACT_APP_TMAP_KEY}&name=오토노바&lon=${result[0].x}&lat=${result[0].y}`);
                } 
            });
        }
    }

    useEffect(() => {
        tmapData(process.env.REACT_APP_ADDR)
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle={"이용 혼잡도"}
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <PageTitle
                    contents={`지금 방문해서 ${tap == "out" ? '외부세차만' : "외부 + 실내세차"} 진행 시<br/><span class='gTextColor'>${tap == "out" ? waitingData?.outside_car_wash : waitingData?.inside_car_wash}분 이내</span> 세차를 완료해요!`}
                    subTitle={"현장 상황에 따라 세차시간에 차이가 있을 수 있습니다."}
                />
                <DegreeTotalInfoBox>
                    <DegreeTotalInfoItem
                        addClass={tap == "out" ? "active" : ""}
                        name="외부세차만 진행 시"
                        caption={`${waitingData?.outside_car_wash}분 이내`}
                        func={()=>{setTap("out")}}
                    />
                    <DegreeTotalInfoItem
                        addClass={tap == "in" ? "active" : ""}
                        name="외부 + 실내세차 진행 시"
                        caption={`${waitingData?.inside_car_wash}분 이내`}
                        func={()=>{setTap("in")}}
                    />
                </DegreeTotalInfoBox>

                <ItemSection>
                    <ItemSectionTitle
                        contents="예상 대기시간"
                    />
                    <WaitingTimeBox>
                        <WaitingTimeItem
                            name="현재"
                            graph={
                                ((tap == "out" ? Number(waitingData?.outside_car_wash) : Number(waitingData?.inside_car_wash)) / Number(waitingData?.max_waiting_time) * 100) >= 100 ? 
                                100 : (((tap == "out" ? Number(waitingData?.outside_car_wash) : Number(waitingData?.inside_car_wash)) / Number(waitingData?.max_waiting_time) * 100)||0)
                            }
                            time={`${tap == "out" ? waitingData?.outside_car_wash : waitingData?.inside_car_wash}분`}
                            addClass="nowTime"
                        />
                        {waitingData?.waiting_times && waitingData?.waiting_times.length > 0 ? 
                            <>
                                {waitingData?.waiting_times?.map((item,i)=>(
                                    <WaitingTimeItem
                                        name={item.time}
                                        graph={
                                            ((tap == "in" ? (Number(item.waiting_time) + 10) : item.waiting_time) / waitingData?.max_waiting_time * 100) >= 100 ? 
                                            100 : (((tap == "in" ? (Number(item.waiting_time) + 10) : item.waiting_time) / waitingData?.max_waiting_time * 100)||0)
                                        }
                                        time={`${tap == "in" ? (Number(item.waiting_time) + 10) : item.waiting_time}분`}
                                        addClass={
                                            (tap == "in" ? (Number(item.waiting_time) + 10) : Number(item.waiting_time)) <= 19 ? "bColor"
                                            : (tap == "in" ? (Number(item.waiting_time) + 10) : Number(item.waiting_time)) <= 30 ? "oColor"
                                            : "rColor"
                                        }
                                    />
                                ))}
                            </>
                        :""}
                    </WaitingTimeBox>
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        btnType="link"
                        btnLink={tmapUrl}
                        contents={"길찾기"}
                        disabled={false}
                        func={() => {}}
                    />
                </BtnBox>
            </ContentsSection>
            
        </PageSizing>
    );
};

export default Degree;