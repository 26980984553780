import { PageTitle } from "component/app/items";
import { BtnItem, BtnText } from "component/basic/btns";
import { setDateTime, slideToggle } from "js/function";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function ReportAboutSection(data){

    return (
        <div className="reportAboutSection">
            {data.children}
        </div>
    );
}

function ReportAboutTextBox(data){

    return (
        <div className={`reportAboutTextBox ${data.addClass||""}`}>
            <PageTitle
                contents={data.title}
                subTitle={null}
            />
            <p className="reportAbout_text" dangerouslySetInnerHTML={{__html:data.text}}/>
            {data.img ? <img src={data.img} className="reportAbout_img"/> : ""}
        </div>
    );
}

function ReportBtnBox(data){

    return (
        <div className="reportBtnBox">
            {data.text ? <BtnText text={data.text}/> : ""}
            <BtnItem
                addClass="subType line"
                contents={data.contents}
                disabled={false}
                func={() => {data.func()}}
            />
        </div>
    );
}

function ReportListItem(data){
    let navigate = useNavigate();

    return (
        <button type="button" onClick={()=>{navigate(`/report/detail/${data.id}`)}} className="reportListItem">
            <div className="reportListItem_titleBox">
                <p className="reportListItem_carNumber gTextColor boldText">{data.car_number}</p>
                <h1 className="reportListItem_title">디테일링 리포트 결과</h1>
            </div>
            <p className="reportListItem_date">{setDateTime(data.created_at,"yymmdd","hymd")}</p>
        </button>
    );
}

function ReportInfoTableItem(data){

    return (
        <div className="reportInfoTableItem">
            <h2 className="reportInfoTable_name">{data.name}점 이상</h2>
            <div className="reportInfoTable_textBox">
                <p className="reportInfoTable_textCaption">월 마다</p>
                <p className="reportInfoTable_text gTextColor boldText">{data.sale}%할인</p>
            </div>
        </div>
    );
}

function ReportInfoContetns(data){

    return (
        <div className="reportInfoContetns">
            <h1 className="reportInfo_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            {data.table ? 
            <div className="reportInfo_table">
                <ReportInfoTableItem
                    name="80"
                    sale="3"
                />
                <ReportInfoTableItem
                    name="90"
                    sale="7"
                />
            </div>
            :""}
            {data.text ? <p className="reportInfo_text" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
            {data.subText ? 
            <div className="reportInfo_subTextBox">
                {data.subText.map((item,i)=>(
                    <p className="reportInfo_subText" key={i} dangerouslySetInnerHTML={{__html:item}}/>
                ))}
            </div>
            : ""}
        </div>
    );
}


function ReportCarImgBox(data){

    return (
        <div className="reportCarImgBox" onClick={()=>{if(data.func){data.func()}}}>
            {data.caption ? 
                <div className="reportCarImgCaption">
                    차량을 터치해서 자세히보기
                    <img src="/assets/images/icon/car_info_icon.svg"/>
                </div>
            :""}
            <img src={`/assets/images/img/${data.img}.png`}/>
        </div>
    );
}

function ReportCarInfoItem(data){

    return (
        <div className="reportCarInfo_item">
            <p className="reportCarInfo_item_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="reportCarInfo_item_value gTextColor" dangerouslySetInnerHTML={{__html:data.value}}/>
        </div>
    );
}


function ReportCarInfo(data){

    return (
        <div className="reportCarInfo">
            <h1 className="reportCarInfo_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <div className="reportCarInfo_itemBox">
                <ReportCarInfoItem
                    name={"20° 광택"}
                    value={`${data.luster20}GU`}
                />
                <span/>
                <ReportCarInfoItem
                    name={"60° 광택"}
                    value={`${data.luster60}GU`}
                />
                <span/>
                <ReportCarInfoItem
                    name={"도막 두께"}
                    value={`${data.paintFilm}㎛`}
                />
            </div>
        </div>
    );
}

function ReportCarInfoContetns(data){

    return (
        <div className="reportCarInfoContetns">
            <ReportCarImgBox
                caption={false}
                img={data.img}
            />
             <div className="reportCarInfo_dataBox">
                {data.data.map((item,i)=>(
                    <ReportCarInfo
                        key={i}
                        name={item.name}
                        luster20={item.luster20}
                        luster60={item.luster60}
                        paintFilm={item.paintFilm}
                    />
                ))}
             </div>
        </div>
    );
}

function ReportCarMainScore(data){

    return (
        <div className="reportCarMainScore">
            <div className="reportCarMainScore_titleBox">
                <button type="button" onClick={()=>{data.func()}} className="reportCarMainScore_info">도장 상태 점수?<img src="/assets/images/icon/info.svg"/></button>
                <p className="reportCarMainScore_title">나의 도장 상태 점수</p>
            </div>
            <p className="reportCarMainScore_score gTextColor boldText">{data.score}</p>
        </div>
    );
}

function ReportCarScoreItem(data){

    return (
        <button type="button" onClick={()=>{data.func()}} className="reportCarScoreItem">
             <p className="reportCarScoreItem_name">{data.name}<img src="/assets/images/icon/info_w.svg"/></p>
             <p className="reportCarScoreItem_score gTextColor boldText">{data.score}</p>
        </button>
    );
}

function ReportCarScore(data){

    return (
        <div className="reportCarScoreBox">
            {data.data.map((item,i)=>(
                <ReportCarScoreItem
                    key={i}
                    name={item.name}
                    score={item.score}
                    func={()=>{item.func()}}
                />
            ))}
        </div>
    );
}

function ReportDetailInfoImg(data){

    return (
        <div className="reportDetailInfoImg backgroundImg" style={{"backgroundImage":`url('${data.img}')`}}>
            {data.caption ? <p className={`reportDetailInfoImg_caption ${data.addClass||""}`}>{data.caption}</p> : ""}
        </div>
    );
}

function ReportDetailInfo(data){
    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);

    const [slideChk,setSlideChk] = useState(true);

    return (
        <div className={`reportDetailInfoBox ${openChk ? "active" : ""}`}>
            <button type="button" onClick={(e)=>{
                if(slideChk){
                    setSlideChk(false);
                    data.func(data.id);
                    setTimeout(function(){
                        slideToggle(slideItem.current,"block")
                        setOpenChk(!openChk)
                    },0);
                    setTimeout(() => {
                        setSlideChk(true);
                    }, 300);
                }
            }} className="reportDetailInfo_titleArea">
                <div className="reportDetailInfo_titleBox">
                    <img src={`/assets/images/icon/${data.icon}.svg`}/>
                    <div className="reportDetailInfo_titleItem">
                        <h2 className="reportDetailInfo_title" dangerouslySetInnerHTML={{__html:data.title}}/>
                        {data.titleCaption ? <p className="reportDetailInfo_titleCaption" dangerouslySetInnerHTML={{__html:data.titleCaption}}/> : ""}
                    </div>
                </div>
                <div className="reportDetailInfo_scoreBox">
                    <p className={`reportDetailInfo_score ${data.scoreColor == "gColor" ? "gTextColor" : data.scoreColor == "yColor" ? "yTextColor" : data.scoreColor == "rColor" ? "rTextColor" : ""}`}>{data.score}</p>
                    <img src="/assets/images/basic/accordion_icon.svg"/>
                </div>
            </button>
            <div className="reportDetailInfo_contents" ref={slideItem}>
                {data.subTitle ? <h1 className={`reportDetailInfo_subTitle ${data.scoreColor == "gColor" ? "gTextColor" : data.scoreColor == "yColor" ? "yTextColor" : data.scoreColor == "rColor" ? "rTextColor" : ""}`} dangerouslySetInnerHTML={{__html:data.subTitle}}/> : ""}
                <p className="reportDetailInfo_text" dangerouslySetInnerHTML={{__html:data.text ? data.text : "-"}}/>
                {data.img_0 || data.img_1 ?
                <div className="reportDetailInfo_imgBox">
                    {data.img_0 ? 
                    <ReportDetailInfoImg
                        addClass={data.img_0_class}
                        caption={data.img_0_text}
                        img={data.img_0}
                    />
                    :""}
                    {data.img_1 ? 
                    <ReportDetailInfoImg
                        addClass={data.img_1_class}
                        caption={data.img_1_text}
                        img={data.img_1}
                    />
                    :""}
                </div>
                :""}
            </div>
        </div>
    );
}

function ReportSection(data){

    return (
        <div className={`reportSection ${data.addClass||""}`}>
            {data.title ? 
            <div className="reportSection_titleBox">
                <h1 className="reportSection_title" dangerouslySetInnerHTML={{__html:data.title}}/>
            </div>
            :""}
            {data.children}
        </div>
    );
}

function ReportFuncBox(data){

    return (
        <div className={`reportFuncBox ${data.addClass||""}`}>
            {data.data?.map((item,i)=>(
                <div className="reportFuncItem" key={i}>
                    <div className="reportFuncItem_titleBox">
                        {item.img ? <img src={`/assets/images/icon/${item.img}`}/> : ""}
                        <h1 className="reportFuncItem_title gTextColor" dangerouslySetInnerHTML={{__html:item.title}}/>
                    </div>
                    <p className="reportFuncItem_text" dangerouslySetInnerHTML={{__html:item.text}}/>
                </div>
            ))}
        </div>
    );
}

export {ReportAboutSection,ReportAboutTextBox,ReportBtnBox,ReportListItem,ReportInfoContetns,ReportCarInfoContetns,ReportCarScore,ReportCarMainScore,ReportCarImgBox,ReportDetailInfo,ReportSection,ReportFuncBox};