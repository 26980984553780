import { comFormat, slideToggle, packageStatus, washIcon } from "js/function";
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function WashListBox(data){

    return (
        <div className="washListBox">
            {data.children}
        </div>
    );
}

function WashListOptionItem(data){

    return (
        <div className="washListOptionItem">
            {washIcon(data.name) ? <img src={washIcon(data.name)}/> : ""}
            <p className="washListOptionItem_name" dangerouslySetInnerHTML={{__html:data.name}}/>
        </div>
    );
}

function WashTypeCaption(data){

    return (
        <p className={`washTypeCaption ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.type}}/>
    );
}

function WashListBtn(data){

    return (
        <button type="button" className={`washListBtn ${data.addClass||""} ${data.isUse ? "washListUseBtn" : ""} ${data.textType ? "washListTextBtn" : ""}`} disabled={data.disabled||false} onClick={()=>{data.func()}} dangerouslySetInnerHTML={{__html:`${data.text}${data.disabled || data.textType ? "" : `<img src="/assets/images/basic/btn_icon.svg"/>`}`}}/>
    );
}

function WashListItem(data){
    let navigate = useNavigate();

    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);

    const [slideChk,setSlideChk] = useState(true);

    useEffect(()=>{
        
    },[])

    return (
        <div className={`washListItem ${data.addClass||""}`}>
            <div className="washListItem_nameBox">
                <div className="washListItem_badgeBox">
                    {data?.is_bonus === 1 && (
                        <div className="washListItem_badge" style={{ backgroundColor: '#73F7CA99', border: "1px solid #73F7CA" }}>FREE</div>
                    )}
                    {data?.badges?.length > 0 && data?.badges?.map((badge,i) => (
                        <div className="washListItem_badge" key={i} style={{ backgroundColor: badge?.color + '99', border: "1px solid " + badge?.color }}>{badge?.name}</div>
                    ))}
                </div>
                <h1 className="washListItem_name">{data.name}</h1>
                <p className="washListItem_caption" dangerouslySetInnerHTML={{__html:data.caption}}/>
                {data?.packageItems && (
                    <div className="washListItem_packageBox">
                        {data?.packageItems?.map((productPackage,i) => (
                            <div className={`washListItem_packageBoxItem ${(productPackage?.status && productPackage?.status !== 0) ? 'disabled' : ''}`} key={i}>
                                {productPackage?.is_bonus === 1 && (<div className="washListItem_packageBoxItemBonus">FREE</div> )}
                                <div className={`washListItem_packageBoxItemName ${productPackage?.is_bonus === 1 && 'bonus'}`}>{productPackage?.name}</div>
                                {productPackage?.product_count ? (<div className="washListItem_packageBoxItemAmount">{productPackage?.product_count + '개'}</div>) : (<div className="washListItem_packageBoxItemStatus">{packageStatus(productPackage?.status)}</div>)}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {data?.option?.length > 0 && (
                <div className="washListItem_optionBox">
                    {data.include_inside_full_care == 1 ?
                        <WashListOptionItem
                            id={null}
                            name={`실내 풀케어 서비스${data.inside_full_care_option_count ? `<br/>(월 ${comFormat(data.inside_full_care_option_count)}회)` : ""}`}
                        />
                        :""}
                    {data.option.map((item,i)=>(
                        <Fragment key={i}>
                            {item.name.indexOf("실내 풀케어 서비스") !== -1 ?
                                "" :
                                <WashListOptionItem
                                    key={i}
                                    id={item.id}
                                    name={item.name}
                                />}
                        </Fragment>
                    ))}
                </div>
            )}
            {data.subOptionTitle ? 
            <div className={`washListItem_subOptionBox ${openChk ? "active" : ""}`}>
                {data.subOption ? 
                    <>
                        <button type="button" className="washListItem_subOptionTitle" onClick={()=>{
                            if(slideChk){
                                setSlideChk(false);
                                setTimeout(function(){
                                    slideToggle(slideItem.current,"flex")
                                    setOpenChk(!openChk)
                                },0);
                                setTimeout(() => {
                                    setSlideChk(true);
                                }, 300);
                            }
                        }} dangerouslySetInnerHTML={{__html:`${data.subOptionTitle} 서비스 포함`}}/>
                        <div className="washListItem_subOption" ref={slideItem}>
                            {data.subOption.map((item,i)=>(
                                <WashListOptionItem
                                    key={i}
                                    id={item.id}
                                    name={item.name}
                                />
                            ))}
                        </div>
                    </>
                :
                    <h1 className="washListItem_subOptionTitle" dangerouslySetInnerHTML={{__html:data.subOptionTitle}}/>
                }
            </div>
            :""}
            {data?.is_bonus === 1 && data?.expiration_day ? <div className="washListItem_expirationDay">구매일로부터 {data?.expiration_day}일</div> : ""}
            {data?.status === 0 && data?.expiration_date ? <div className="washListItem_expirationDay">유효기간 : {data?.expiration_date}</div> : ""}
            {data?.type === 'package' ? (<WashListBtn
                addClass={""}
                textType={data.textBtn}
                /*text={`<span class="washListItem_packagePrice"><span class="washListItem_price">${comFormat(data.price)}원 <span class="washListItem_originalPrice">${comFormat(data?.originalPrice)}원</span></span><span class="washListItem_percent">${data?.percent}% 할인</span></span>`}*/
                text={`<span class="washListItem_packagePrice"><span class="washListItem_price">${comFormat(data.price)}원 <span class="washListItem_originalPrice">${comFormat(data?.originalPrice)}원</span></span></span>`}
                disabled={data.btn0_disabled}
                isUse={data.isUse}
                func={()=>{
                    navigate(`/package/detail/${data.id}`)
                }}
            />) : data.use_once == 1 || data.use_month == 1 || data.btn0Text ?
                <div className="washListItem_btnBox">
                    {(data.use_once == 1 && (data.price || data.price == 0)) || data.btn0Text ?
                        <WashListBtn
                            addClass={""}
                            textType={data.textBtn}
                            text={data.btn0Text ? data.btn0Text : `1회권 ${comFormat(data.price)}원`}
                            disabled={data.btn0_disabled}
                            isUse={data.isUse}
                            func={()=>{
                                if (data?.brn0Func) {
                                    data?.brn0Func()
                                } else {
                                    if(data.ticketType){
                                        navigate(`/wash/ticket/detail/${data.id}`)
                                    }else if(!data.textBtn){
                                        navigate(`/wash/payment/${data.id}/one`)
                                    }
                                }
                            }}
                        />
                        :""}
                    {data.use_month == 1 && (data.month_price || data.month_price == 0) ?
                        <WashListBtn
                            addClass={"monthBtn"}
                            textType={data.textBtn}
                            text={data.btn1Text ? data.btn1Text : `구독권 ${comFormat(data.month_price)}원`}
                            disabled={data.btn1_disabled}
                            isUse={data.isUse}
                            func={()=>{
                                if(data.ticketType){
                                    navigate(`/wash/ticket/detail/${data.id}`)
                                }else if(!data.textBtn){
                                    navigate(`/wash/payment/${data.id}/month`)
                                }
                            }}
                        />
                        :""}
                </div>
                :""}
            {}
        </div>
    );
}

function TopLink(data){

    return (
        <button type="button" onClick={()=>{data.func()}} className="topLink">
            <h1 className="topLink_name" dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="topLink_caption">{data.subText ? data.subText : "시작"}<img src="/assets/images/basic/btn_icon.svg"/></p>
        </button>
    );
}

export {WashListBox,WashListOptionItem,WashTypeCaption,WashListBtn,WashListItem,TopLink};