import * as fatchSet from "../api/api";

//핸드폰번호 포멧
function PhoneSetting(str) {
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
        return str;
    } else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
    }

    return str;
}

function birthSetting(str) {
	str = str.replace(/[^0-9]/g, '');
	var tmp = '';
	if (str.length < 7) {
		return str.slice(0, 9);
	} else {
		tmp += str.substr(0, 6);
		tmp += '-';
		tmp += str.substr(6);

		return tmp.slice(0, 9);
	}
}

//유효기간 형식 세팅
function cardDateSetting(str) {
	str = str.replace(/[^0-9]/g, "");
	var tmp = "";
  
	if (str.length < 3) {
	  return str;
	} else if (str.length < 5) {
	  tmp += str.substr(0, 2);
	  tmp += "/";
	  tmp += str.substr(2);
	  return tmp;
	} else if (str.length >= 5 && str.indexOf("/") == -1) {
	  str = str.substr(0, 4);
  
	  tmp += str.substr(0, 2);
	  tmp += "/";
	  tmp += str.substr(2);
	  return tmp;
	}
  
	return str;
}

//날짜 표기형식 세팅
function setDateTime(item,type,unit){
	let setDate = "";
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate),
			yy,
			mm,
			dd,
			th,
			tm,
			ts;

		yy = date.getFullYear();
		mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
		dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
		th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
		tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
		ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();
		
		if(type == "yymmdd"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일";
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd;
			}else if(unit == "not"){
				setDate = `${yy}${mm}${dd}`;
			}else{
				setDate = yy + "." + mm + "." + dd;
			}
		}else if(type == "yymmddhhtt"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
			}
		}else if(type == "mmdd"){
			if(unit == "ymd"){
				setDate = mm + "월 " + dd + "일";
			}else if(unit == "hymd"){
				setDate = mm + "-" + dd;
			}else if(unit == "slash"){
				setDate = mm + "/" + dd;
			}else{
				setDate = mm + "." + dd;
			}
		}else if(type == "yymm"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월";
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm;
			}else if(unit == "slash"){
				setDate = yy + "/" + mm;
			}else{
				setDate = yy + "." + mm;
			}
		}else if(type == "day"){
			setDate = dd;
		}else if(type == "yymmddhhttss"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm + ":" + ts;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm + ":" + ts;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
			}
		}else if(type == "hhttss"){
			setDate = th + ":" + tm + ":" + ts;
		}else if(type == "hhtt"){
			setDate = th + ":" + tm;
		}else{
			setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
		}
	}
	return setDate;
}

//날짜 요일 세팅
function setDateWeek(item){
    const weekArr = ["일","월","화","수","목","금","토"];
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate)
		let setWeek = weekArr[date.getDay()];

		return setWeek;
	}else{
		return "";
	}
}

//날짜 차이
function getDateGap(start,end,type,todayChk){
	if(start){
		let startDate = new Date(start.replace(/\.-/g,"/")),
			endDate = new Date(end.replace(/\.-/g,"/"));

		let dateGap = endDate.getTime() - startDate.getTime();
		let timeGap = new Date(0, 0, 0, 0, 0, 0, endDate - startDate);

		let setDay = Math.floor(dateGap / (1000 * 60 * 60 * 24));   
		let setH = timeGap.getHours();
		let setM  = timeGap.getMinutes();

		let setting = type == "day" ? setDay + (todayChk ? 0 : 1) : `${setDay + (todayChk ? 0 : 1)}일 ${setH}시간 ${setM}분`;
		
		return setting;
	}else{
		return 8;
	}
}

//시간 차이
function getTimeGap(start,end,type){
	if(start){
		let startDate = new Date(start.replace(/\.-/g,"/")),
			endDate = end ? new Date(end.replace(/\.-/g,"/")) : new Date();
		let setting = "";

			const dateSet = endDate - startDate;
			const ss = dateSet / 1000;
			const mm = dateSet / 1000 / 60;
			const hh = dateSet / 1000 / 60 / 60;

			if(type == "ss"){
				setting = ss;
			}else if(type == "mm"){
				setting = mm;
			}else if(type == "hh"){
				setting = hh;
			}
		
		return setting;
	}
}

//나이 계산
function ageSetting(birth){
	let today = new Date();
	let birthDate = birth ? birth.length > 4 ? new Date(birth.replace(/\.-/g,"/")) : new Date(birth, 1, 1) : "";
	let age = birth ? today.getFullYear() - birthDate.getFullYear() + 1 : null;

	return age;
}

//url 파라미터
function getParam(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = params.split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

function getParamText(sname,url) {
	var urlSet = new URL(url);
	var params = urlSet.search.substr(urlSet.search.indexOf("?") + 1);
	var sval = "";
	params = params.split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//url 파라미터 소셜 로그인용
function getParam_sns(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = window.location.href.split("#")[1].split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//스크롤 하단 체크
function bottomChk(){
    let scrollTop = document.documentElement.scrollTop,
		innerHeight = window.innerHeight,
		scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

    if (Math.ceil(scrollTop + innerHeight) + 1 >= scrollHeight) {
        return true;
    } else {
        return false;
    }
}

//토글 슬라이드
function slideUp(target, duration){
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.boxSizing = "border-box";
	target.style.height = target.offsetHeight + "px";
	target.style.overflow = "hidden";
	window.setTimeout(() => {
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
	}, 10);
	window.setTimeout(() => {
		target.style.display = "none";
		target.style.removeProperty("height");
		target.style.removeProperty("padding-top");
		target.style.removeProperty("padding-bottom");
		target.style.removeProperty("margin-top");
		target.style.removeProperty("margin-bottom");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideDown(target, duration, type){
	target.style.removeProperty("display");
	let display = window.getComputedStyle(target).display;

	if (display === "none") display = type == "flex" ? "flex" : "block";

	target.style.display = display;
	let height = target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.style.height = target.offsetHeight + "px";
	target.style.boxSizing = "border-box";
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.height = height + "px";
	target.style.removeProperty("padding-top");
	target.style.removeProperty("padding-bottom");
	target.style.removeProperty("margin-top");
	target.style.removeProperty("margin-bottom");
	window.setTimeout(() => {
		target.style.removeProperty("height");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideToggle(target, type){
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, 300, type);
  } else {
    return slideUp(target, 300);
  }
}

//스크롤 이동
function moveScroll(item,addTop){
	let scrollItem = document.querySelectorAll(item)[0].offsetTop;
	window.scrollTo({left: 0, top: addTop ? scrollItem - addTop : scrollItem, behavior: "smooth"});
}

//스크롤 제한(팝업용)
let scrollPosition = 0;
let chkType = false;
function bodyScrollDisabled(type){
	const scrollItem = document.querySelector("body");

	if(type){
		if(!chkType){
			chkType = true;
			if(window.pageYOffset !== 0){scrollPosition = window.pageYOffset;sessionStorage.setItem("scroll",window.pageYOffset)}
			scrollItem.style.overflow = "hidden";
			scrollItem.style.position = "fixed";
			scrollItem.style.top = `-${scrollPosition}px`;
		}
	}else{
		if(chkType){
			chkType = false;
			scrollItem.style.removeProperty("overflow");
			scrollItem.style.removeProperty("position");
			scrollItem.style.removeProperty("top");
			window.scrollTo(0, scrollPosition);
			scrollPosition = 0;
			sessionStorage.removeItem("scroll")
		}
	}
}

function bodyScroll_limit(){
	document.querySelectorAll("body")[0].classList.add("active");
}
function bodyScroll_clear(){
	document.querySelectorAll("body")[0].classList.remove("active");
}

//천단위 콤마 포멧
function comFormat(number){
	let data = Number(number);

	if (data == 0) return 0;
	var reg = /(^[+-]?\d+)(\d{3})/;
	var n = (data + '');
	while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
	return n;
}

//dday 체크
function dDay(data){
	const now = new Date();
	const use_date = new Date(data.replace(/-/g,"/"));

	const setDate = use_date - now;

	const day = Math.floor(setDate / (1000 * 60 * 60 * 24));

	return day;
}

//평일,주말 체크
function getWeekCount(start,end) {
    let startDate = new Date(start.replace(/-/g,"/")),
     	endDate = new Date(end.replace(/-/g,"/"));

    let weekdayCount = 0,
		weekendCount = 0;

	while(true) {
		let setStartDate = startDate;				
		if(setStartDate.getTime() > endDate.getTime()) {
			return [weekdayCount,weekendCount];
		} else {
			let tmp = setStartDate.getDay();
			if(tmp == 0 || tmp == 6) {//주말에 금요일 제외 필요하면 tmp == 5 추가
				weekendCount++;
			} else {
				weekdayCount++;         
			}
			setStartDate.setDate(startDate.getDate() + 1); 
		}
	}
}

//스타일 추출
function getStyle(el, prop, type){
	let style;
    if(el.currentStyle){
        style = el.currentStyle[prop];
    }else if(window.getComputedStyle){
        style = type == "number" ? window.getComputedStyle(el,null)[prop].replace(/[a-zA-Z#?!@$ %^&*-]/gi,"") : window.getComputedStyle(el,null)[prop];
    }
    return style;
}

//로그아웃
function logout(){
	localStorage.removeItem("token");
	localStorage.removeItem("refresh_token");
}

function basicImgSet(img){
	if(img){
		return img;
	}else{
		return "/assets/images/img/basic_profile.svg";
	}
}

function timeArr(timeStart,timeEnd,timeUnit){
	let timeSet = Number(timeStart);
	let timeArr = [];

	do {
		let h = String(timeSet).substring(0,String(timeSet).length <= 3 ? 1 : 2),
			m = String(timeSet).substring(String(timeSet).length <= 3 ? 1 : 2,String(timeSet).length <= 3 ? 3 : 4);
		
		if(m >= 60){
			m = m - 60;
			h = Number(h) + 1;
		}

		if(Number(m) < 10){
			m = `0${Number(m)}`;
		}

		if(Number(h) < 10){
			h = `0${Number(h)}`;
		}

		timeArr.push(`${h}:${m}`);
		timeSet = Number(`${h}${m}`) + timeUnit;
	} while (timeSet <= Number(timeEnd));

	return timeArr;
}

function washIcon(text){
	let iconUrl = "";
	if(text.indexOf("실내 풀케어 서비스") !== -1){
		iconUrl = "icon_0"
	}
	if(text == "휠&타이어 샤인" || text.indexOf("휠&타이어 샤인") !== -1){
		iconUrl = "icon_1"
	}
	if(text.indexOf("더블 브러시") !== -1 || text.indexOf("더블브러시") !== -1){
		iconUrl = "icon_2"
	}
	if(text.indexOf("스팟 프리") !== -1 || text.indexOf("스팟프리") !== -1){
		iconUrl = "icon_3"
	}
	if(text.indexOf("슈퍼 드라잉") !== -1 || text.indexOf("슈퍼드라잉") !== -1){
		iconUrl = "icon_4"
	}
	if(text.indexOf("라바 폼") !== -1 || text.indexOf("라바폼") !== -1){
		iconUrl = "icon_5"
	}
	if(text.indexOf("트리플 폼") !== -1 || text.indexOf("트리플폼") !== -1){
		iconUrl = "icon_6"
	}
	if(text.indexOf("범퍼 블라스터") !== -1 || text.indexOf("범퍼블라스터") !== -1){
		iconUrl = "icon_7"
	}
	if(text.indexOf("트리플 코팅") !== -1 || text.indexOf("트리플코팅") !== -1){
		iconUrl = "icon_8"
	}
	if(text.indexOf("프리 워시") !== -1 || text.indexOf("프리워시") !== -1){
		iconUrl = "icon_9"
	}
	if(text.indexOf("실내 진공 청소") !== -1){
		iconUrl = "icon_10"
	}
	if(text.indexOf("매트 세척") !== -1){
		iconUrl = "icon_11"
	}
	if(text.indexOf("윈도우 클리닝") !== -1){
		iconUrl = "icon_12"
	}
	if(text.indexOf("내장재 클리닝") !== -1){
		iconUrl = "icon_13"
	}
	if(text.indexOf("타이어 드레싱") !== -1){
		iconUrl = "icon_14"
	}
	if(text.indexOf("실내 수소 살균") !== -1){
		iconUrl = "icon_15"
	}
	if(text.indexOf("휠 코팅") !== -1){
		iconUrl = "icon_16"
	}
	if(text.indexOf("그래핀 워터 코팅") !== -1){
		iconUrl = "icon_17"
	}
	if(text.indexOf("내장재 코팅") !== -1){
		iconUrl = "icon_18"
	}
	if(text.indexOf("카나우바 왁스") !== -1){
		iconUrl = "icon_19"
	}
	return iconUrl ? `/assets/images/wash/${iconUrl}.png` : ""
}

function productCaption(text){
	let caption = "";
	if(text.indexOf("5") !== -1){
		caption = 5;
	}

	if(text.indexOf("7") !== -1){
		caption = 5;
	}

	if(text.indexOf("9") !== -1){
		caption = 5;
	}
	
	return `${caption}${text.indexOf("PLUS") !== -1 ? "+" : ""}`;
}

function paymentMethod(text){
	let type = "";
	if(text == "CARD" || text == "card"){
		type = "신용/체크카드";
	}
	if(text == "KAKAO" || text == "kakao"){
		type = "카카오페이";
	}
	if(text == "NAVER" || text == "naver"){
		type = "네이버페이";
	}
	if(text == "CASH" || text == "cash"){
		type = "캐시";
	}
	
	return type;
}

function packageStatus(status) {
	if (status == 0) {
		return "사용가능";
	} else if (status == 1) {
		return "사용완료"
	} else if (status == 5) {
		return "기간만료"
	}
}

export {PhoneSetting, birthSetting, cardDateSetting, bottomChk, slideUp, slideDown, slideToggle, ageSetting, getParam, getParamText, getParam_sns, comFormat, dDay, setDateTime, moveScroll, getDateGap, getTimeGap, setDateWeek, getStyle, getWeekCount, bodyScrollDisabled, bodyScroll_limit, bodyScroll_clear,logout, basicImgSet,timeArr,washIcon,productCaption,paymentMethod,packageStatus};