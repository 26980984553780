import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, ItemSection, ItemSectionTitle, NameTextItem, NameTextItemBox, PageSizing } from "component/app/items";
import { LoadingBox, Popup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { WashListItem, WashTypeCaption } from "component/wash/item";
import { comFormat, getDateGap, paymentMethod, setDateTime } from "js/function";
import { QrBox } from "component/payment/item";

const WashTicketDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const varUA = navigator.userAgent;
    const id = param.id;

    const [toastPopupData,setToastPopupData] =  useState(null);
    const [popupData,setPopupData] =  useState(null);
    const [qrCode,setQrCode] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);

    const [submitChk,setSubmitChk] =  useState(true);

    const washApi = useGet({
        url:`/car/wash/detail?car_wash_reservation_id=${id}`,
        loginType:"login"
    });
    const washData = washApi?.data?.car_wash_reservation;

    useEffect(() => {
        setLoadingBoxOpen(true);
        setTimeout(() => {
            setLoadingBoxOpen(false);
        }, 3000);
    }, []);

    useEffect(() => {
        if(washData){
            setLoadingBoxOpen(false);
        }
    }, [washApi]);
    
    function dateGapChk(){
        return getDateGap(setDateTime(new Date(),"yymmdd","hymd"),setDateTime(washData?.payment_date,"yymmdd","hymd"),"day",true);
    }

    function monthCancelOpen(){
        setPopupData({
            addClass:null,
            title:"구독 취소",
            text:(dateGapChk() == 0 && washData?.use_reservation == 0) ? `구독을 취소하시겠습니까?<br/>지금 취소하시면 신청하신 구독은 즉시 취소 처리가 가능합니다.`
            : `구독을 취소하시겠습니까?<br/> 지금 구독을 취소하시더라도 ${washData?.cancel_last_date ? setDateTime(washData?.cancel_last_date,"yymmdd","ymd") : "-"}까지 구독을 이용하실 수 있습니다.`,
            closeType:false,
            closeFunc:(e) => {},
            btnFunc0:(e)=>{},
            btn0:"지금 안함",
            btn0Type:"",
            btn1:"확인",
            btn1Type:"",
            btnFunc1:()=>{monthCancel()}
        });
    }

    function monthCancel(){
        if(submitChk){
            setSubmitChk(false);
            let formData = new FormData();
            formData.append("car_wash_reservation_id", id);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/car/wash/reservation/cancel`,
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"구독 취소 완료",
                        text:`구독 취소가 완료되었습니다.${dateGapChk() == 0 ? "" : `<br/> 해당 구독권은 ${washData?.cancel_last_date ? setDateTime(washData?.cancel_last_date,"yymmdd","ymd") : "-"}까지 이용이 가능합니다.`}`,
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{navigate(-1)},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:false,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="이용권 상세 정보"
                centerTitle={true}
                rightBtns={(washData?.available_cancel == 1 && !washData?.gift_date) ? true : false}
                rightBtnsArr={(washData?.available_cancel == 1 && !washData?.gift_date) ? washData?.type == "once" ? [{addClass:"",contents:"구매취소",func:()=>{navigate(`/wash/cancel/${id}`)}}] : [{addClass:"",contents:"구독취소",func:()=>{monthCancelOpen()}}] : null}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    {washData?.package_reservation_id ? (
                        washData?.product_packages?.map((item,i)=>(
                            <WashListItem
                                key={i}
                                addClass={item?.is_bonus === 1 ? 'bonus' : ''}
                                id={item.id}
                                name={item.name||""}
                                badges={item.product_badges||""}
                                caption={item.contents||""}
                                option={item.product_options||[]}
                                subOptionTitle={item.relation_product_name||null}
                                subOption={item.relation_product_options||[]}
                                include_inside_full_care={item.include_inside_full_care}
                                inside_full_care_option_count={item.inside_full_care_option_count}
                                use_once={item.use_once}
                                use_month={item.use_month}
                                price={item.price}
                                month_price={item.month_price}
                                status={item.status}
                                btn0Text={item.status === 1 ? '사용완료' : (item.status === 5 ? '기간만료' : (item.status === 6 ? '' : (item.status === 4 ? '' : 'QR 코드로 사용')))}
                                brn0Func={() => {
                                    if (item.status === 0) {
                                        setQrCode(item?.qr_code)
                                        setToastPopupData({
                                            addClass:null,
                                            title:null,
                                            closeType:true,
                                            closeFuncChk:false,
                                            closeFunc:(e) => {},
                                            btnFunc0:(e)=>{},
                                            btn0:"닫기",
                                            btn0Type:"",
                                            btn1:"",
                                            btn1Type:"",
                                            btnFunc1:()=>{},
                                            textBtn:"",
                                            textBtnFunc:()=>{

                                            }})
                                    }
                                }}
                                detail={true}
                                isUse={item?.status !== 0}
                                is_bonus={item?.is_bonus}
                                expiration_date={item?.expiration_date}
                                textBtn={item?.status !== 0}
                            />
                        ))
                    ) : (
                        <WashListItem
                            id={washData?.id}
                            status={washData?.status}
                            name={washData ? <><WashTypeCaption type={washData?.type == "once" ? "1회권" : "구독권"} addClass={washData?.type == "once" ? "bColor" : "gColor"}/>{washData?.name}</> : ""}
                            caption={washData?.contents||""}
                            option={washData?.product_options||[]}
                            subOptionTitle={washData?.relation_product_name||null}
                            subOption={washData?.relation_product_options||[]}
                            include_inside_full_care={washData?.include_inside_full_care}
                            inside_full_care_option_count={washData?.inside_full_care_option_count}
                            use_once={null}
                            use_month={null}
                            price={washData?.price}
                            month_price={washData?.month_price}
                            isUse={washData?.status == 0 ? false : true}
                            btn0Text={washData?.status == 0 ? "사용가능" : washData?.status == 1 ? "사용완료" : washData?.status == 3 ? "취소요청" : washData?.status == 4 ? "취소완료" : washData?.status == 5 ? "기간만료" : washData?.status == 6 ? "선물완료" : ""}
                            ticketType={false}
                            expiration_date={washData?.expiration_date}
                            textBtn={true}
                        />
                    )}
                </ItemSection>
                {(!washData?.gift_date || (washData?.gift_date && washData?.status == 6)) && (
                    <ItemSection>
                        <ItemSectionTitle
                            contents="결제정보"
                        />
                        <NameTextItemBox>
                            <NameTextItem
                                name="상품명"
                                text={washData?.name ? `${washData?.name} (${washData?.type == "once" ? "1회권" : "구독권"})` : "-"}
                            />
                            <NameTextItem
                                name="상품금액"
                                text={washData?.product_price ? `${comFormat(washData?.product_price||0)} 원` : "-"}
                            />
                            <NameTextItem
                                name="결제일시"
                                text={washData?.payment_date ? setDateTime(washData?.payment_date,"yymmdd","hymd") : "-"}
                            />
                            <NameTextItem
                                name="결제수단"
                                text={washData?.payment_method ? paymentMethod(washData?.payment_method) : "-"}
                            />
                            <NameTextItem
                                name="할인정보"
                                text={washData?.user_coupon_id ? `${washData?.coupon_name}<br/><span class="gTextColor">${comFormat(washData?.coupon_price)||0}원 할인 적용</span>` : "-"}
                            />
                            <NameTextItem
                                name="총 결제금액"
                                text={washData?.payment_price ? `${comFormat(washData?.payment_price||0)} 원` : "-"}
                            />

                        </NameTextItemBox>
                    </ItemSection>
                )}
                <ItemSection>
                    <ItemSectionTitle
                        contents="이용정보"
                    />
                    <NameTextItemBox>
                        <NameTextItem
                            name="이용상태"
                            text={washData?.status != null ? `${washData?.status == 0 ? "사용가능" : washData?.status == 1 ? "사용완료" : washData?.status == 3 ? "취소요청" : washData?.status == 4 ? "취소완료" : washData?.status == 5 ? "기간만료" : washData?.status == 6 ? "선물완료" : ""}
                            ${washData?.status == 1 && washData?.complete_date ? `<br/>${setDateTime(washData?.complete_date,"yymmddhhttss","hymd")}`:""}
                            ${washData?.status == 6 ? ` (${washData?.gift_target_phone?.substr(0, 3) + '-****-' + washData?.gift_target_phone?.substr(7, 4)})<br/>${setDateTime(washData?.gift_date,"yymmddhhttss","hymd")}`:""}` : "-"}
                        />
                        {(washData?.gift_date && washData?.status != 6) && (
                            <NameTextItem
                                name="유효기간"
                                text={washData?.expiration_date}
                            />
                        )}
                    </NameTextItemBox>
                </ItemSection>
                {washData?.package_reservation_id && (<ItemSection>
                    패키지 상품 안내사항 <br/><br/>
                    <div className="washListItem_packageGuideBox">
                        <p className="washListItem_packageGuideText">패키지 상품은 여러 이용권을 한 번에 할인된 금액으로 구매 가능한 상품으로 포함된 이용권을 1개 이상 사용하실 경우 부분 환불이 불가합니다.<br/></p>
                        <p className="washListItem_packageGuideText">패키지 상품에 보너스 이용권이 포함된 경우 유효기간이 존재하며 기간 내 사용하지 않을 경우 이용권은 소멸합니다.<br/></p>
                        <p className="washListItem_packageGuideText">패키지 상품에 포함된 이용권은 다른 사용자에게 선물이 불가합니다.<br/></p>
                    </div>
                </ItemSection>)}
                {washData?.status == 0 ? 
                <BtnBox
                    boxType="fixed"
                    addClass={!washData?.package_reservation_id && (((washData?.package_reservation_id && washData?.available_cancel === 1) || !(washData?.gift_date && washData?.status != 6)) && washData?.type === "once") ? "col2" : ""}
                >
                    {!washData?.package_reservation_id ? 
                    <BtnItem
                        addClass={(((washData?.package_reservation_id && washData?.available_cancel === 1) || !(washData?.gift_date && washData?.status != 6)) && washData?.type === "once") ? "col2" : ""}
                        contents={"QR 코드로 사용하기"}
                        disabled={false}
                        func={() => {
                            setQrCode(washData?.qr_code)
                            setToastPopupData({
                                addClass:null,
                                title:null,
                                closeType:true,
                                closeFuncChk:false,
                                closeFunc:(e) => {},
                                btnFunc0:(e)=>{},
                                btn0:"닫기",
                                btn0Type:"",
                                btn1:"",
                                btn1Type:"",
                                btnFunc1:()=>{},
                                textBtn:"",
                                textBtnFunc:()=>{

                                }
                            });
                        }}
                    />
                    : ""}
                    {((washData?.package_reservation_id && washData?.available_cancel === 1) || !(washData?.gift_date && washData?.status != 6)) && washData?.type === "once" ? 
                        <BtnItem
                            addClass={!washData?.package_reservation_id ? "col2 subBtn" : "subBtn"}
                            contents={"선물하기"}
                            disabled={false}
                            func={() => {
                                navigate(`/wash/gift/${washData.id}`)
                            }}
                        />
                    :""}
                </BtnBox>
                : washData?.status === 6 ? (
                    <BtnBox
                        boxType="fixed"
                        addClass=""
                    >
                        <BtnItem
                            addClass=""
                            contents={"선물 메시지 재전송"}
                            disabled={false}
                            func={() => {
                                if (varUA.match("autonova/Android") != null) {
                                    window.autonova.shareKakao(washData?.id?.toString(), localStorage.getItem("token"))
                                } else if (varUA.match("autonova/iOS") != null) {
                                    window.webkit.messageHandlers.shareKakao.postMessage({"messageVarId":washData?.id?.toString(),"ident":localStorage.getItem("token")});
                                }
                            }}
                        />
                    </BtnBox>
                    ) : ""}
            </ContentsSection>
            <ToastPopup
                data={toastPopupData}
            >
                <ToastBasicContents
                    title="QR코드"
                    text="타인에게 QR코드를 공유하지 마세요."
                />
                <QrBox qrImg={qrCode}/>
            </ToastPopup>
            <Popup
                data={popupData}
            />
            <LoadingBox openType={loadingBoxOpen}/>
        </PageSizing>
    );
};

export default WashTicketDetail;