import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { CustomSelect, InputItemBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, ItemSection, PageSizing } from "component/app/items";
import { Popup } from "component/basic/popup";
import { comFormat } from "js/function";
import bankData from "../../data/data.json";

const CashRefund = (props) => {
    const navigate = useNavigate();

    const [popupData,setPopupData] =  useState(null);

    const cashApi = useGet({
        url:`/cash?page=1`,
        loginType:"login"
    });
    const myCash = cashApi?.data?.cash;

    const [refundCash,setRefundCash] =  useState("");
    const [bankCode,setBankCode] =  useState({});
    const [bankAccount,setBankAccount] =  useState("");
    const [bankHolder,setBankHolder] =  useState("");

    const [btnChk,setBtnChk] =  useState(true);
    
    const [submitChk,setSubmitChk] =  useState(true);

    function refundSubmit(){
        if(submitChk){
            setSubmitChk(false);
            let formData = new FormData();
            formData.append("price", refundCash);
            formData.append("bank_code", bankCode?.code);
            formData.append("bank_account", bankAccount);
            formData.append("bank_holder", bankHolder);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                loginType:"login",
                url: `/cash/refund/request`,
                success: (data) => {
                    setSubmitChk(true);
                    setPopupData({
                        addClass:null,
                        title:"환불 신청 완료",
                        text:"환불 신청이 완료되었습니다.<br/><span class='gTextColor'>3-5 영업일 이내</span> 처리될 예정입니다.",
                        closeType:false,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{navigate(-1)},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                },
                err: (data) => {
                    setSubmitChk(true);
                    if(data.data || data.alert){
                        setPopupData({
                            addClass:null,
                            title:data.alert||"",
                            text:data.data||"",
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc0:(e)=>{},
                            btn0:"확인",
                            btn0Type:"",
                            btn1:"",
                            btn1Type:"",
                            btnFunc1:()=>{}
                        });
                    }
                }
            })
        }
    }

    function btnChkFunc(){
        if(bankCode?.code && bankAccount !== "" && bankHolder !== "" && refundCash !== "" && refundCash > 0){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }

    useEffect(() => {
        if(refundCash > myCash){
            setRefundCash(myCash > 0 ? String(myCash) : "");
        }
    }, [refundCash]);

    useEffect(() => {
        btnChkFunc();
    }, [bankCode,bankAccount,bankHolder,refundCash]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="환불 신청"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection addClass="notPaddingTop">
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="환불 신청금액"
                        placeholder="환불 신청금액"
                        value={comFormat(refundCash)}
                        max={null}
                        regexp={"numberCom"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setRefundCash(value.replace(/\,/g,""))}}
                        caption={`나의 캐시 : <span class='gTextColor'>${myCash ? comFormat(myCash) : 0}C</span>`}
                    />
                </ItemSection>
                <ItemSection addClass="minLine notPaddingTop">
                    <CustomSelect
                        addClass=""
                        inputName="은행명"
                        placeholder="은행명"
                        value={bankCode?.code}
                        func={(name,val) => {
                            let setData = {
                                "code":val,
                                "name":name
                            }
                            setBankCode(setData);
                        }}
                        disabled={false}
                        valKey="code"
                        nameKey="name"
                        options={bankData?.bankCades}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="계좌번호"
                        placeholder="계좌번호"
                        value={bankAccount}
                        max={null}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{setBankAccount(value)}}
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="예금주"
                        placeholder="예금주"
                        value={bankHolder}
                        max={null}
                        regexp={null}
                        func={(value)=>{setBankHolder(value)}}
                    />
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"환불 신청"}
                        disabled={btnChk}
                        func={() => {
                            refundSubmit()
                        }}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default CashRefund;