import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, ItemSection, ItemSectionTitle, NameTextItem, NameTextItemBox, PageSizing } from "component/app/items";
import { Popup, ToastBasicContents, ToastPopup } from "component/basic/popup";
import { WashListItem, WashTypeCaption } from "component/wash/item";
import { comFormat, getDateGap, paymentMethod, setDateTime } from "js/function";
import { QrBox } from "component/payment/item";
import {InputItemBox} from "../../component/basic/formItems";

const WashTicketGift = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const varUA = navigator.userAgent;
    const id = param.id;

    const [popupData,setPopupData] =  useState(null);
    const [phone, setPhone] =  useState(null);
    const [btnCheck, setBtnCheck] = useState(true);

    const washApi = useGet({
        url:`/car/wash/detail?car_wash_reservation_id=${id}`,
        loginType:"login"
    });
    const washData = washApi?.data?.car_wash_reservation;

    function giftSubmit(){
        let formData = new FormData();
        formData.append("car_wash_reservation_id", id);
        formData.append("phone", phone);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            loginType:"login",
            url: `/car/wash/send/gift`,
            success: (data) => {
                setPopupData({
                    addClass:null,
                    title:"선물하기 완료",
                    text:phone + "님에게 " + washData?.name + "선물을 완료했습니다.",
                    closeType:false,
                    closeFunc:(e) => {},
                    btnFunc0:(e)=>{navigate(-1)},
                    btn0:"확인",
                    btn0Type:"",
                    btn1:"",
                    btn1Type:"",
                    btnFunc1:()=>{}
                });

                if (varUA.match("autonova/Android") != null) {
                    window.autonova.shareKakao(id?.toString(), localStorage.getItem("token"))
                } else if (varUA.match("autonova/iOS") != null) {
                    window.webkit.messageHandlers.shareKakao.postMessage({"messageVarId":id?.toString(),"ident":localStorage.getItem("token")});
                }
            },
            err: (data) => {
                if(data.data || data.alert){
                    setPopupData({
                        addClass:null,
                        title:data.alert||"",
                        text:data.data||"",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc0:(e)=>{},
                        btn0:"확인",
                        btn0Type:"",
                        btn1:"",
                        btn1Type:"",
                        btnFunc1:()=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        if(washData && washData?.type === "month"){
            navigate(-1);
        }
    }, [washApi]);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="이용권 선물하기"
                centerTitle={true}
                rightBtns={false}
            />
            <ContentsSection addClass="headerContents btnContents">
                <ItemSection>
                    {washData?.package_reservation_id ? (
                        washData?.product_packages?.map((item,i)=>(
                            <WashListItem
                                key={i}
                                addClass={(item?.is_bonus === 1 ? 'bonus' : '') + (i == (washData?.product_packages?.length - 1) ? ' bottomMargin' : '')}
                                id={item.id}
                                name={item.name||""}
                                badges={item.product_badges||""}
                                caption={item.contents||""}
                                option={item.product_options||[]}
                                subOptionTitle={item.relation_product_name||null}
                                subOption={item.relation_product_options||[]}
                                include_inside_full_care={item.include_inside_full_care}
                                inside_full_care_option_count={item.inside_full_care_option_count}
                                use_once={item.use_once}
                                use_month={item.use_month}
                                price={item.price}
                                month_price={item.month_price}
                                detail={true}
                                isUse={item?.status !== 0}
                                is_bonus={item?.is_bonus}
                                expiration_date={item?.expiration_date}
                                textBtn={item?.status !== 0}
                                isGift={true}
                            />
                        ))
                    ) : (
                        <WashListItem
                            id={washData?.id}
                            addClass="bottomMargin"
                            name={washData ? <><WashTypeCaption type={washData?.type == "once" ? "1회권" : "구독권"} addClass={washData?.type == "once" ? "bColor" : "gColor"}/>{washData?.name}</> : ""}
                            caption={washData?.contents||""}
                            option={washData?.product_options||[]}
                            subOptionTitle={washData?.relation_product_name||null}
                            subOption={washData?.relation_product_options||[]}
                            include_inside_full_care={washData?.include_inside_full_care}
                            inside_full_care_option_count={washData?.inside_full_care_option_count}
                            use_once={null}
                            use_month={null}
                            price={washData?.price}
                            month_price={washData?.month_price}
                            ticketType={false}
                            textBtn={true}
                        />
                    )}
                    <ItemSectionTitle
                        contents="선물할 대상자 정보"
                    />
                    <InputItemBox
                        inputType={null}
                        addClass=""
                        inputName="연락처"
                        placeholder="연락처"
                        value={phone}
                        max={11}
                        regexp={"number"}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        func={(value)=>{
                            setPhone(value)
                            if (value !== "") {
                                setBtnCheck(false)
                            } else {
                                setBtnCheck(true)
                            }
                        }}
                    />
                </ItemSection>
                <ItemSection>
                    안내사항 <br/><br/>

                    <div className="washListItem_packageGuideBox">
                        <p className="washListItem_packageGuideText">선물은 회원에게도 가능하며 입력하신 연락처를 기준으로 선물받은 대상자에게 이용권이 자동 지급됩니다.<br/></p>
                        <p className="washListItem_packageGuideText">이용권을 선물할 경우 선물 받은 대상자에게 안내 문자가 발송되며 입력한 연락처 정보로 회원가입이 완료될 경우 이용권이 자동 지급됩니다.<br/></p>
                        <p className="washListItem_packageGuideText">선물 받은 대상자가 회원이 아닌 경우 선물을 발송한 일로부터 14일 이내 대상자가 회원가입을 진행하지 않으면 선물한 이용권은 소멸합니다.<br/></p>
                        <p className="washListItem_packageGuideText">추천하기를 통해 보너스 이용권이 지급되는 대상자에게 이용권을 선물할 경우 보너스 이용권은 지급되지 않습니다.<br/></p>
                    </div>
                </ItemSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"이용권 선물하기"}
                        disabled={btnCheck}
                        func={() => {
                            giftSubmit()
                        }}
                    />
                </BtnBox>
            </ContentsSection>
            <Popup
                data={popupData}
            />
        </PageSizing>
    );
};

export default WashTicketGift;