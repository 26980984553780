function RecommendedPopup(data){
    return (
        <div className={`recommendedPopupItem ${data.addClass||""}`}>
            <img src="/assets/images/img/recommended_img.svg"/>
            <h1 className="recommendedPopupItem_title">회원가입 완료!<br/>친구에게 오토노바 추천하기</h1>
            <p className="recommendedPopupItem_text">오토노바를 추천하고 친구에게 보너스 이용권을 선물해 보세요.</p>
        </div>
    );
}

function RecommendedProductBox(data){
    return (
        <div className={`recommendedProductBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function RecommendedProduct(data){
    return (
        <div className={`recommendedProduct ${data.addClass||""}`}>
            <h1 className="recommendedProduct_name" dangerouslySetInnerHTML={{__html:data.name}}></h1>
            <p className="recommendedProduct_count">{data.qty ? data.qty : 0}장</p>
        </div>
    );
}

function RecommendedInfo(data){
    return (
        <div className={`recommendedInfo ${data.addClass||""}`}>
            <h1 className="recommendedInfo_name" dangerouslySetInnerHTML={{__html:data.name}}></h1>
            {data.infoItem.map((item,i)=>(
                <div className="recommendedInfo_textBox" key={i}>
                    <span/>
                    <p className="recommendedInfo_text" dangerouslySetInnerHTML={{__html:item}}/>
                </div>
            ))}
        </div>
    );
}

function RecommendedInfoBox(data){
    return (
        <div className={`recommendedInfoBox ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function RecommendedSection(data){
    return (
        <div className={`recommendedSection ${data.addClass||""}`}>
            {data.children}
        </div>
    );
}

function RecommendedBadge(data){
    return (
        <p className="recommendedBadge" dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

export {RecommendedPopup,RecommendedProduct,RecommendedInfo,RecommendedInfoBox,RecommendedSection,RecommendedBadge,RecommendedProductBox};