import { cloneElement, useEffect, useState } from "react";
import { BtnBox, PopupBtnBox } from "./btns";
import * as fatchSet from "../../api/api";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { bodyScrollDisabled, comFormat } from "js/function";
import useGet from "api/useGet";
import { Fragment } from "react";
import { ChkBox } from "./formItems";

//우측 슬라이드 팝업
function RightPopup(data){
    const [popupOpen,setPopupOpen] =  useState("block");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    useEffect(()=>{
        if(data.openType){
            setPopupData(data.openType)
            bodyScrollDisabled(true);
        }else{
            setPopupData(null)
            bodyScrollDisabled(false);
        }
    },[data.openType]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                // setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 200);
            }, 200);
            bodyScrollDisabled(true);
        }else{
            setPopupClass("");
            setTimeout(() => {
                // setPopupOpen("none");
            }, 200);
            bodyScrollDisabled(false);
        }
    },[popupData]);

    return (
        <div className={`rightPopup ${popupClass ? popupClass : ""} ${data.addClass ? data.addClass : ""} ${data.bType ? "bPopup" : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {setPopupData(null);data.closePopup && data.closePopup(e)}}></div>
            <div className="rightPopup_section">
                <div className="rightPopup_header_section">
                    {data.topTitle ? <h1 className="rightPopup_title" dangerouslySetInnerHTML={{__html:data.topTitle}}></h1> : ""}
                    <button type="button" className="btn_back rightPopupClose" onClick={(e) => {setPopupData(null);data.closePopup && data.closePopup(e)}}><img src={`/assets/images/basic/${data.bType ? "close_w.svg" : "close.svg"}`} alt="back_icon"/></button>
                </div>
                <div className="rightPopup_contents">
                    {data.children && data.children}
                </div>
            </div>
        </div>
    );
}

//로딩
function LoadingBox(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupClass,setPopupClass] =  useState(null);

    useEffect(()=>{
        if(data.openType){
            setPopupOpen("block");
            setPopupClass("active");
            bodyScrollDisabled(true);
        }else{
            setPopupClass("");
            setPopupOpen("none");
            bodyScrollDisabled(false);
        }
    },[data.openType]);

    return (
        <div className={`loadingBox ${popupClass||""} ${data.addClass||""}`} style={{display:popupOpen}}>
            <div className="popup_cover"></div>
            <div id="loading" className="loading">
                <div className="loadingImg">
                    <img src="/assets/images/loading/car.svg" className="loading_carImg"/>
                    <img src="/assets/images/loading/wheel.svg" className="loading_wheelImg"/>
                </div>
                <div className="floorItemBox">
                    <span className="floorItem"></span>
                    <span className="floorItem"></span>
                </div>
            </div>
        </div>
    );
}

//하단 메세지 문구
let timeOut = "";
function BottomErrMsg(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");

    useEffect(()=>{
        if(data.text && data.text !== ""){
            clearTimeout(timeOut);

            setPopupData(data.text);
            
            setPopupOpen("block");
            setTimeout(() => {
                setPopupClass("active");
            }, 100);

            timeOut = setTimeout(() => {
                setPopupClass("");
                setTimeout(() => {
                    setPopupOpen("none");
                }, 200);
            }, 2000);
        }
    },[data.chk]);

    return (
        <p className={`bottomErrMsg ${popupClass ? popupClass : ""} ${data.addClass ? data.addClass : ""}`} style={{display:popupOpen}} dangerouslySetInnerHTML={{__html:popupData}}></p>
    );
}

//기본팝업
function Popup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScrollDisabled(true);
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScrollDisabled(false);
        }
    },[popupData]);

    return (
        <div className={`popup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFunc && popupData?.closeFunc(e)}}/>
            <div className="popupSetion">
                <div className="popupTextBox">
                    {popupData?.title ? <h2 className="popupTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    <p className="popupText" dangerouslySetInnerHTML={{__html:popupData?.text}}/>
                    {data?.children}
                </div>
                <div className={`popup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
                    <button type="button" className={`btn_popup btn_popupLeft ${popupData?.btn0Type ? popupData?.btn0Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc0(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn0}}/>
                    {popupData?.btn1 ? 
                        <>
                        {popupData?.btn1Type == "link" ? 
                            <a href={popupData?.btn1Link} target="_blank" className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} onClick={(e) => {if(!popupData?.notClose){setPopupData(null);popupData?.btnFunc1(e)}}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/>
                        :
                            <button type="button" className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} disabled={popupData?.btn1Disabled ? popupData?.btn1Disabled : false} onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/>
                        }
                        </>
                    : ""}
                </div>
            </div>
        </div>
    );
}

//토스트팝업
function ToastBasicContents(data){
    return (
        <div className="toastBasicContents">
            {data.title ? <h1 className="toastBasicContents_title" dangerouslySetInnerHTML={{__html:data?.title}}/> : ""}
            {data.text ? <p className="toastBasicContents_text" dangerouslySetInnerHTML={{__html:data?.text}}/> : ""}
        </div>
    );
}

function ToastPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    const [chkVal,setChkVal] =  useState(popupData?.chkType||false);
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScrollDisabled(true);
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScrollDisabled(false);
        }
    },[popupData]);

    return (
        <div className={`toastPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFuncChk && popupData?.closeFunc(e)}}/>
            <div className="popupSetion">
                {popupData?.title || popupData?.closeFuncChk || data.header ?
                <div className="popupHaeder">
                    {data.header||""}
                    {popupData?.headBtn ? <button type="button" className="popupHeadBtn" onClick={(e) => {popupData?.headBtnFunc(e)}}>{popupData?.headBtn}</button> : ""}
                    {popupData?.title ? <h2 className="popupHaederTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    {/* {popupData?.closeFuncChk ? <button type="button" className="popupClose" onClick={(e) => {setPopupData(null);popupData?.closeFuncChk && popupData.closeFunc(e)}}><img src={`/assets/images/basic/close.svg`} alt="back_icon"/></button> : ""} */}
                    {popupData?.textBtn ? <button type="button" className="textBtn" onClick={(e) => {setPopupData(null);popupData.textBtnFunc()}} dangerouslySetInnerHTML={{__html:popupData?.textBtn}}/> : ""}
                </div> : ""}
                <div className="popupContents">
                    {data.children && data.children}
                </div>
                {popupData?.btnChkBox ? 
                    <div className="popup_btnChkBox">
                        <ChkBox
                            addClass=""
                            func={(e) => {popupData?.btnChkFunc(e.target.checked);setChkVal(e.target.checked)}}
                            label={popupData?.btnChkFuncLabel}
                            chkFunc={(e) => popupData?.btnChkClickFunc()}
                            checkedType={popupData?.chkType||chkVal}
                        />
                    </div>
                :""}
                {popupData?.btn0 ? 
                <div className={`popup_btnBox toastPopup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
                    <button type="button" className={`btn_popup btn_popupLeft ${popupData?.btn0Type ? popupData?.btn0Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc0(e)}} disabled={popupData?.btn0Disabled ? popupData?.btn0Disabled : false} dangerouslySetInnerHTML={{__html:popupData?.btn0}}/>
                    {popupData?.btn1 ? <button type="button" disabled={popupData?.btn1Disabled ? popupData?.btn1Disabled : false} className={`btn_popup btn_popupRight ${popupData?.btn1Type ? popupData?.btn1Type : ""}`} onClick={(e) => {setPopupData(null);popupData?.btnFunc1(e)}} dangerouslySetInnerHTML={{__html:popupData?.btn1}}/> : ""}
                </div>
                :""}
            </div>
        </div>
    );
}

//선택팝업
function SelPopup(data){
    const [popupOpen,setPopupOpen] =  useState("none");
    const [popupData,setPopupData] =  useState(null);
    const [popupClass,setPopupClass] =  useState("");
    
    useEffect(()=>{
        setPopupData(data.data)
    },[data.data]);

    useEffect(()=>{
        if(popupData){
            setTimeout(() => {
                setPopupOpen("block");
                setTimeout(() => {
                    setPopupClass("active");
                }, 100);
            }, 200);
            bodyScrollDisabled(true);
        }else{
            setPopupClass("");
            setTimeout(() => {
                setPopupOpen("none");
            }, 200);
            bodyScrollDisabled(false);
        }
    },[popupData]);

    return (
        <div className={`selPopup ${popupClass ? popupClass : ""} ${popupData?.addClass ? popupData?.addClass : ""}`} style={{display:popupOpen}}>
            <div className="popup_cover" onClick={(e) => {popupData?.closeType && setPopupData(null);popupData?.closeType && popupData?.closeFuncChk && popupData?.closeFunc(e)}}></div>
            <div className="selPopupSetion">
                <div className="popupHaeder">
                    {popupData?.title ? <h2 className="popupHaederTitle" dangerouslySetInnerHTML={{__html:popupData?.title}}/> : ""}
                    {/* <button type="button" className="popupClose" onClick={(e) => {setPopupData(null);popupData?.closeFuncChk && popupData.closeFunc(e)}}><img src={`/assets/images/basic/close.svg`} alt="back_icon"/></button> */}
                </div>
                <div className="popupContents">
                    {data.children && data.children}
                </div>
                <div className="selPopup_selBox yScroll">
                    {popupData?.dataKey ? 
                        popupData?.data?.length > 0 ?
                        popupData?.data.map((item, i) => (
                                <button type="button" key={i} className={`selPopup_sel ${item[popupData?.dataKey] == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:item.id, text:item[popupData?.dataKey]})}} dangerouslySetInnerHTML={{__html:item[popupData?.dataKey]}}></button>
                            ))
                            : ""
                        : 
                        popupData?.items?.map((item, i) => (
                            popupData?.val[i] == "notSel" ?
                            <p key={i} className="selPopup_selSub_category" dangerouslySetInnerHTML={{__html:item}}></p>
                            :
                            popupData?.val[i] == "lineItem" ?
                            <p key={i} className="selPopup_selSub_lineItem"></p>
                            :
                            <button type="button" key={i} className={`selPopup_sel ${item == popupData?.sel || popupData?.val[i] == popupData?.sel ? "active" : ""}`} onClick={(e) => {setPopupData(null);popupData?.func({val:popupData?.val[i], text:item})}} dangerouslySetInnerHTML={{__html:item}}></button>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}


function SlideUpPopup(data){
    const [moveType,setMoveType] =  useState("");
    const [startEvent,setStartEvent] =  useState(false);
    const [startDownEvent,setStartDownEvent] =  useState(false);

    const [popupActiveClass,setPopupActiveClass] =  useState("");
    const [coverActiveClass,setCoverActiveClass] =  useState("");
    const [popupOpenClass,setPopupOpenClass] =  useState("");
    const [popupTransform,setPopupTransform] =  useState("translateY(calc(100% - 82px))");

    const titleBox = useRef(null);
    const iconBox = useRef(null);

    const [startInfo,setStartInfo] =  useState({
        startX: -1,
        startY: -1,
        startTime: 0,
        start_point: 0
    });

    //upType 구분
    function getMoveTypeUp(x, y){
        let reMoveType = {
        type: "down",
        y: 0,
        };
    
        let nY = startInfo.startY - y;
        reMoveType.y = nY <= 0 ? 0 : nY;

        if((nY - (sessionStorage.getItem("scroll") ? sessionStorage.getItem("scroll") : 0)) >= 80){
            reMoveType.type = "up";
        }
    
        return reMoveType;
    }

    //downType 구분
    function getMoveTypeDown(x, y){
        let reMoveType = {
        type: "up",
        y: 0,
        };
    
        let nY = startInfo.startY - y;
            reMoveType.y = nY >= 0 ? 0 : nY;
        if(nY <= -150){
            reMoveType.type = "down";
        }
    
        return reMoveType;
    }

    //초기화
    function initTouchInfo(){
        let startSet = {
            startX: -1,
            startY: -1,
            startTime: 0,
            start_point: 0
        }

        setStartInfo(startSet);
    }

    //슬라이드 팝업(강제)
    function slidePopupUp(){
        setStartEvent(false);
        initTouchInfo();
        setMoveType("");

        setPopupActiveClass("active");
        setCoverActiveClass("active");
        setPopupTransform("translateY(0)");
        setPopupOpenClass("open");
    }
  
    function slidePopupDown(){
        setPopupActiveClass("active");

        setStartDownEvent(false);
        initTouchInfo();
        
        setMoveType("");
        setPopupOpenClass("");
        setCoverActiveClass("");
        setPopupTransform("translateY(calc(100% - 82px))");

        setTimeout(() => {
            setPopupActiveClass("");
        }, 500);
    }

    //up event
    function upTouchstart(e){
        if(popupOpenClass == ""){
            if(titleBox.current.getBoundingClientRect().top > 70){
                let startSet = {
                    startX: e.touches[0].pageX,
                    startY: e.touches[0].pageY,
                    startTime: e.timeStamp,
                    start_point: e.touches[0].pageY
                }
            
                setStartInfo(startSet);
                setStartEvent(true);
            }

            bodyScrollDisabled(true);
        }
    }

    function upTouchmove(e){
        if(startEvent && popupOpenClass == ""){
            var moveX = e.touches[0].pageX;
            var moveY = e.touches[0].pageY;
            var moveDis = moveX + moveY;
            setMoveType(getMoveTypeUp(moveX, moveY));
            setPopupTransform(`translateY(calc(100% - ${82 + getMoveTypeUp(moveX, moveY).y - (sessionStorage.getItem("scroll") ? sessionStorage.getItem("scroll") : 0)}px))`);

            if(moveType.type == "up"){
                setStartEvent(false);
                initTouchInfo();
                setMoveType("");

                setPopupActiveClass("active");
                setCoverActiveClass("active");
                setPopupTransform("translateY(0)");
                setPopupOpenClass("open");
            }
        }
    }

    function upTouchend(e){
        if(popupOpenClass == ""){
            if(startEvent){
                if(moveType.y >= 80){
                    setPopupActiveClass("active");
                    setCoverActiveClass("active");
                    setPopupTransform("translateY(0)");
                    setPopupOpenClass("open");

                    bodyScrollDisabled(true);
                }else{
                    setPopupActiveClass("active");
                    setPopupTransform("translateY(calc(100% - 82px))");
                    setCoverActiveClass("");
                    setTimeout(() => {
                        setPopupActiveClass("");
                    }, 500);

                    bodyScrollDisabled(false);
                }

                setMoveType("");
                setStartEvent(false);
                initTouchInfo();
            }else{
                bodyScrollDisabled(false);
            }
        }
    }

    //down event
    function downTouchstart(e){
        if(iconBox.current.getBoundingClientRect().top >= 40 && popupOpenClass=="open"){
            let startSet = {
                startX: e.touches[0].pageX,
                startY: e.touches[0].pageY,
                startTime: e.timeStamp,
                start_point: e.touches[0].pageY
            }

            setStartInfo(startSet);
            setStartDownEvent(true);
        }
    }

    function downTouchmove(e){
        if(startDownEvent && popupOpenClass=="open"){
            var moveX = e.touches[0].pageX;
            var moveY = e.touches[0].pageY;
            var moveDis = moveX + moveY;
            setMoveType(getMoveTypeDown(moveX, moveY));

            setPopupTransform(`translateY(${Math.abs(getMoveTypeDown(moveX, moveY).y)}px)`);

            if(moveType.type == "down"){
                setPopupActiveClass("active");
                setStartDownEvent(false);
                initTouchInfo();
                setMoveType("");

                setPopupOpenClass("");
                setCoverActiveClass("");
                setPopupTransform("translateY(calc(100% - 82px))");

                setTimeout(() => {
                    setPopupActiveClass("");
                }, 500);
            }
        }
    }

    function downTouchend(e){
        if(startDownEvent && popupOpenClass=="open"){
            if(Math.abs(moveType.y) >= 70){
                setPopupOpenClass("");
                setCoverActiveClass("");
                setPopupTransform("translateY(calc(100% - 82px))");

                setTimeout(() => {
                    setPopupActiveClass("");
                }, 500);
            }else{
                setPopupActiveClass("active");
                setPopupTransform("translateY(0)");
            }
            setMoveType("");
            setStartDownEvent(false);

            initTouchInfo();
        }
    }

    useEffect(()=>{
        if(popupActiveClass == "active" || popupOpenClass=="open"){
            bodyScrollDisabled(true);
        }else{
            bodyScrollDisabled(false);
        }
    },[popupActiveClass, popupOpenClass]);

    useEffect(()=>{
        slidePopupDown();
    },[data.slideUpPopupClose]);
    
    return (
        <>
            <div className={`slideUpPopup_cover ${coverActiveClass||""}`} onClick={()=>{slidePopupDown()}}></div>
            <div className={`slideUpPopup ${data?.addClass ? data?.addClass : ""} ${popupActiveClass||""} ${popupOpenClass||""}`} style={{transform:popupTransform}}>
                <div className="slideUpPopup_section" onTouchStart={(e)=>{if(popupActiveClass == "active"){downTouchstart(e)}else{upTouchstart(e)}}} onTouchMove={(e)=>{if(popupActiveClass == "active"){downTouchmove(e)}else{upTouchmove(e)}}} onTouchEnd={(e)=>{if(popupActiveClass == "active"){downTouchend(e)}else{upTouchend(e)}}}>
                    <div className="scroll_sel_icon" ref={iconBox}>
                        <img src={`/assets/images/basic/${popupOpenClass == "" ? "slideUp_icon_off" : "slideUp_icon_on"}.svg`}/>
                    </div>
                    <div className="slideUpPopup_titleBox" ref={titleBox}>
                        {data.title}
                    </div>
                    <div className="slideUpPopup_contents">
                        {data.children}
                    </div>
                </div>
            </div>
        </>
    );
}


function NoticePopup(data){
    const [popupTransform,setPopupTransform] =  useState("translateY(calc(100% - 80px))");

    const titleBox = useRef(null);

    return (
        <>
            <div className={`slideUpPopup ${data?.addClass ? data?.addClass : ""}`} style={{transform:popupTransform}}>
                <div className="slideUpPopup_section">
                    <div className="slideUpPopup_titleBox" ref={titleBox}>
                        {data.title}
                    </div>
                    <div className="slideUpPopup_contents">
                        {data.children}
                    </div>
                </div>
            </div>
        </>
    );
}

export {Popup, ToastPopup, SelPopup, LoadingBox, RightPopup, BottomErrMsg, ToastBasicContents, SlideUpPopup, NoticePopup}