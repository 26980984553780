import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { Tap } from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import { TopLink, WashListBox, WashListItem } from "component/wash/item";

const WashList = (props) => {
    const navigate = useNavigate();

    const washApi = useGet({
        url:`/car/wash/product?car_size=${localStorage.getItem("carSize")}`,
        loginType:"login"
    });

    const washData = washApi?.data?.products||[];

    useEffect(() => {
        
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={false}
                headTitle={null}
            >
                <Tap
                    addClass=""
                    func={(id)=>{
                        navigate(`/${id}`)
                    }}
                    val="wash"
                    data={[
                        {id:"package",category:"패키지"},
                        {id:"wash",category:"카워시"},
                        {id:"detailing",category:"디테일링"},
                        // {id:"dentrepair",category:"덴트리페어"}
                    ]}
                />
            </Header>
            <ContentsSection addClass="headerContents footerContents">
                {/* <TopLink name="나의 세차 패턴에 맞는 이용권 알아보기" func={()=>{navigate("/member/survey")}}/> */}
                <WashListBox>
                    {washData?.map((item,i)=>(
                        <WashListItem 
                            key={i}
                            id={item.id}
                            name={item.name||""}
                            badges={item.product_badges||""}
                            caption={item.contents||""}
                            option={item.product_options||[]}
                            subOptionTitle={item.relation_product_name||null}
                            subOption={item.relation_product_options||[]}
                            include_inside_full_care={item.include_inside_full_care}
                            inside_full_care_option_count={item.inside_full_care_option_count}
                            use_once={item.use_once}
                            use_month={item.use_month}
                            price={item.price}
                            month_price={item.month_price}
                        />
                    ))}
                </WashListBox>
            </ContentsSection>
            <FooterManu menuChk="1"/>
        </PageSizing>
    );
};

export default WashList;