import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContentsSection, PageSizing } from "component/app/items";
import { SubTap, Tap } from "component/basic/tap";
import { EventItem } from "component/board/item";
import { NotItems } from "component/basic/notItems";
import { bottomChk, getDateGap, setDateTime } from "js/function";
import { ChannelTalkLoad } from "component/app/channelTalk";

const Event = (props) => {
    const navigate = useNavigate();

    const [page,setPage] =  useState(1);

    const [itemList,setItemList] =  useState([]);

    let lastPage = false;
    let settingChk = 0;

    const [tap,setTap] = useState("");

    const pageDataApi = useGet({
        url:`/event?status=${tap}&page=${page}`,
        loginType:"login"
    });
    const pageData = pageDataApi?.data?.events;

    function addPage(){
        setPage(page + 1);
        settingChk = 1;
        setTimeout(() => {
            settingChk = 0;
        }, 300);
    };

    useEffect(() => {
        if(pageData?.data && pageData?.data.length > 0){
            if(page == 1){
                setItemList([...pageData?.data]);
            }else{    
                setItemList(prev => [...prev, ...pageData?.data]);
            }
        }else{
            setItemList([]);
        }

        lastPage = pageData?.current_page < pageData?.last_page;

        window.removeEventListener("scroll", handleScroll);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pageDataApi]);

    function handleScroll(){
        if(bottomChk() && lastPage && settingChk == 0){
            addPage();
        }
    };

    useEffect(() => {
        setItemList([]);
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <PageSizing addClass="headerPage">
            <Header
                pageBack={true}
                func={() => {navigate(-1)}}
                headTitle="고객센터"
                centerTitle={true}
                homeBtn={true}
            >
                <Tap
                    addClass="headerComTap"
                    func={(id)=>{
                        navigate(`/${id}`)
                    }}
                    val="event"
                    data={[
                        {id:"notice",category:"공지사항"},
                        {id:"event",category:"이벤트"},
                        {id:"faq",category:"FAQ"}
                    ]}
                />
            </Header>
            <ContentsSection addClass="headerTapContents bottomBasicPadding">
                <SubTap
                    addClass=""
                    func={(id)=>{setTap(id)}}
                    val={tap}
                    data={[
                        {id:"",category:"전체"},
                        {id:"now",category:"진행중"},
                        {id:"end",category:"종료"}
                    ]}
                />
                <div className="eventListBox">
                    {itemList?.length > 0 ?
                        <>
                            {itemList && itemList?.map((item, i) => (
                                <EventItem
                                    key={i}
                                    id={item.id}
                                    addClass=""
                                    sub_link={item.sub_link}
                                    bgImg={item.image_url}
                                    type={item.end_date && item.end_date !== "0000-00-00" ? getDateGap(setDateTime(new Date(),"yymmdd","hymd"),item.end_date,"day") <= 0 ? "종료" : "진행중" : "종료"}
                                    title={item.title}
                                    text={item.contents}
                                    start_date={item.start_date}
                                    end_date={item.end_date}
                                />
                            ))}
                        </>
                    :
                        <NotItems
                            addClass="fullPage"
                            img="/assets/images/icon/not_event.svg"
                            text={`${tap == "now" ? "진행중인" : tap == "end" ? "종료된" : "등록된"} 이벤트가 없습니다.`}
                        />
                    }
                </div>
            </ContentsSection>
            <ChannelTalkLoad/>
        </PageSizing>
    );
};

export default Event;